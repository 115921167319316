import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Admin.scss';
import { LinkItem } from 'components';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Admins from './Admins/Admins';
import ServiceWorks from './ServiceWorks/ServiceWorks';
import TestsResults from './TestsResults/TestsResults';
import { useUser } from 'reducers';
import { useMediaQuery } from 'react-responsive';
import { MD } from 'lib/util';
import AssortmentGraphics from './AssortmentGraphics/AssortmentGraphics';
import CacheManagement from './CacheManagement/CacheManagement';
import Statistics from './Statistics/Statistics';
import News from './News/News';
import Postals from './Postals/Postals';

const Admin = () => {
    const [activeKey, setActiveKey] = useState('admins');
    const location = useLocation();
    const isDesktop = useMediaQuery({ minWidth: MD });
    const history = useHistory();
    const user = useUser();

    useEffect(() => {
        if (location.pathname === `${process.env.PUBLIC_URL}/admin`) {
            if (user?.permissions.canManageAdmins) {
                history.replace(`${process.env.PUBLIC_URL}/admin/admins`);
            } else if (user?.permissions.canManageServiceWorks) {
                history.replace(`${process.env.PUBLIC_URL}/admin/service-works`);
            } else if (user?.permissions.canViewTests) {
                history.replace(`${process.env.PUBLIC_URL}/admin/test-results`);
            } else if (user?.permissions.canManageAssortmentImages) {
                history.replace(`${process.env.PUBLIC_URL}/admin/assortment-graphics`);
            } else if (user?.permissions.canManageNews) {
                history.replace(`${process.env.PUBLIC_URL}/admin/news`);
            } else if (user?.permissions.canManageCache) {
                history.replace(`${process.env.PUBLIC_URL}/admin/cache-management`);
            } else if (user?.permissions.canViewStatistics) {
                history.replace(`${process.env.PUBLIC_URL}/admin/statistics`);
            } else if (user?.permissions.canUpdatePostals) {
                history.replace(`${process.env.PUBLIC_URL}/admin/postals`);
            }
        }
        if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/admins`)) {
            setActiveKey('admins');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/service-works`)) {
            setActiveKey('service-works');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/test-results`)) {
            setActiveKey('test-results');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/assortment-graphics`)) {
            setActiveKey('assortment-graphics');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/news`)) {
            setActiveKey('news');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/cache-management`)) {
            setActiveKey('cache-management');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/statistics`)) {
            setActiveKey('statistics');
        } else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/postals`)) {
            setActiveKey('postals');
        }
    }, [location.pathname]);

    return (
        <>
            {(user?.permissions.anyAdminTabPermission() && isDesktop) && (
                <Navbar className='sub-navbar' expand='lg'>
                    <Nav
                        justify
                        variant='pills'
                        className='flex-grow-1 flex-shrink-1'
                        activeKey={activeKey}
                    >
                        {user?.permissions.canManageAdmins && <LinkItem to={`${process.env.PUBLIC_URL}/admin/admins`} eventKey='admins'>Administratorzy</LinkItem>}
                        {user?.permissions.canManageServiceWorks && <LinkItem to={`${process.env.PUBLIC_URL}/admin/service-works`} eventKey='service-works'>Prace serwisowe</LinkItem>}
                        {user?.permissions.canViewTests && <LinkItem to={`${process.env.PUBLIC_URL}/admin/test-results`} eventKey='test-results'>Wyniki testów</LinkItem>}
                        {user?.permissions.canManageAssortmentImages && <LinkItem to={`${process.env.PUBLIC_URL}/admin/assortment-graphics`} eventKey='assortment-graphics'>Grafiki asortymentu</LinkItem>}
                        {user?.permissions.canManageNews && <LinkItem to={`${process.env.PUBLIC_URL}/admin/news`} eventKey='news'>Aktualności</LinkItem>}
                        {user?.permissions.canManageCache && <LinkItem to={`${process.env.PUBLIC_URL}/admin/cache-management`} eventKey='cache-management'>Cache</LinkItem>}
                        {user?.permissions.canViewStatistics && <LinkItem to={`${process.env.PUBLIC_URL}/admin/statistics`} eventKey='statistics'>Statystyki</LinkItem>}
                        {user?.permissions.canUpdatePostals && <LinkItem to={`${process.env.PUBLIC_URL}/admin/postals`} eventKey='postals'>Baza kodów pocztowych</LinkItem>}
                    </Nav>
                </Navbar>
            )}
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/admin/admins`}>
                    {user?.permissions.canManageAdmins && <Admins />}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/admin/service-works`}>
                    {user?.permissions.canManageServiceWorks && <ServiceWorks />}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/admin/test-results`}>
                    {user?.permissions.canViewTests && <TestsResults />}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/admin/assortment-graphics`}>
                    {user?.permissions.canManageAssortmentImages && <AssortmentGraphics />}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/admin/news`}>
                    {user?.permissions.canManageNews && <News />}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/admin/cache-management`}>
                    {user?.permissions.canManageCache && <CacheManagement />}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/admin/statistics`}>
                    {user?.permissions.canViewStatistics && <Statistics />}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/admin/postals`}>
                    {user?.permissions.canUpdatePostals && <Postals />}
                </Route>
            </Switch>
        </>
    );
};

export default Admin;
