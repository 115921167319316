import { API_ADDRESS, get, post } from 'lib/communication';
import store from 'store';
import { dataActions } from 'reducers';
import { WebserviceTestResult, SchemaValidationResultsResponse, SchemaValidationLatestResultsResponse } from 'lib/types';
import { produce } from 'immer';

const setWebserviceTestsFetching = (isFetching: object) => {
    const state = store.getState();
    store.dispatch(
        dataActions.setWebserviceTests(
            produce(state.data.webserviceTestResults, (draft) => {
                draft.props = {
                    ...draft.props,
                    ...isFetching
                };
            })
        )
    );
};

const setSchemaValidationResultsFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setWebServiceSchemaValidationResultsFetching(isFetching));
};

export const getSchemaValidationResults = async (numberOfResults: number): Promise<boolean> => {
    const route: string = 'tests/getSchemaValidationResults';
    setSchemaValidationResultsFetching(true);
    try {
        const data = await post<SchemaValidationResultsResponse>(`${API_ADDRESS}${route}`, {
            numberOfResults
        });
        if (data.ok && data.parsedBody) {
            store.dispatch(dataActions.setSchemaValidationResults(data.parsedBody));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setSchemaValidationResultsFetching(false);
    }
};

export const getTestsResults = async (): Promise<boolean> => {
    const route: string = 'tests/getTestsResults';
    setWebserviceTestsFetching({ isFetching: true });
    try {
        const data = await get<WebserviceTestResult[]>(`${API_ADDRESS}${route}`);
        if (data.ok && data.parsedBody) {
            store.dispatch(dataActions.setWebserviceTestsResults(data.parsedBody));
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setWebserviceTestsFetching({ isFetching: false });
    }
};

export const runTests = async (
    value: string
): Promise<boolean> => {
    const route: string = 'tests/runTests';
    try {
        const response = await post<Response>(`${API_ADDRESS}${route}`, { value });
        if (response.ok && response.parsedBody) {
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
};

const setLatestSchemaValidationResultsFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setWebServiceLatestSchemaValidationResultsFetching(isFetching));
};

export const getLatestSchemaValidationResults = async (numberOfResults: number): Promise<boolean> => {
    const route: string = 'tests/getLatestSchemaValidationResults';
    setLatestSchemaValidationResultsFetching(true);
    try {
        const data = await post<SchemaValidationLatestResultsResponse>(`${API_ADDRESS}${route}`, {
            numberOfResults
        });
        if (data.ok && data.parsedBody) {
            store.dispatch(dataActions.setLatestSchemaValidationResults(data.parsedBody));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error', err);
        return false;
    } finally {
        setLatestSchemaValidationResultsFetching(false);
    }
};
