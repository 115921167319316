import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState, dataActions } from 'reducers';
import './AssortmentSelection.scss';
import _ from 'lodash';
import { Product, CartContent, ViewId } from 'lib/types';
import { mapLocalCartToCartContents } from 'lib/utilityMetods';
import { isLeaf, makeRoot, mapTree, TreeNode } from 'lib/tree';
import GutterCreator from 'components/GutterCreator';
import PlatesCreator from 'components/PlatesCreator';
import Cart from '../Cart';
import CartReset from '../CartReset';
import store from 'store';
import { getProductsByGroup, getGroups, getProductWithPrice } from 'lib/communication/orders';
import SimilarProducts from './ProductSelection/SimilarProducts';
import ProductSelection from './ProductSelection/ProductSelection';
import AdditionalAssortment from './ProductSelection/AdditionalAssortment';
import AssortmentSelectionHeader from './AssortmentSelectionHeader';
import BasicView from './BasicView';
import AdvancedView from './AdvancedView';
import { produce } from 'immer';
import ButtonComponent from 'components/ButtonComponent';
import { userService } from 'services';
import useDebounce from 'lib/hooks/useDebounce';

export const BASIC_GROUP = 'KWWW';
export const SPECIAL_GROUP = '';
export const GROUP_CATEGORY_NAME = ['Rodzaj', 'Typ', 'Powłoka', 'Grubość', 'Kolor'];
export const GROUP_ORDER = [1, 2, 3, 4, 5];

export const pathToArray = (path: string): string[] => path.split('\\');

export const isSpecialGroup = (selectedPath: string): boolean => _.includes(pathToArray(selectedPath), SPECIAL_GROUP);

export const firstNGroups = (path: string, n: number | undefined): string => pathToArray(path).slice(0, n).join('\\');

export const arrayPathToString = (pathArray: string[]): string => pathArray.join('\\');

interface AssortmentSelectionProps {
    goToSummary: () => void;
    cartReset: boolean;
    setCartReset: (value: boolean) => void;
    loadedCart: React.MutableRefObject<boolean>;
}

export type AssortmentSelectionState = {
    selectedView: ViewId;
    selectedPath: string;
    selectedProduct?: Product | null;
    editedOrderId: string | null;
};

const ASSORTMENT_SELECTION_STATE: AssortmentSelectionState = {
    selectedView: 'basic',
    selectedPath: BASIC_GROUP,
    selectedProduct: null,
    editedOrderId: null
};

const AssortmentSelection = (props: AssortmentSelectionProps) => {
    const history = useHistory<AssortmentSelectionState>();

    const assortmentState: AssortmentSelectionState = history.location.state ? (history.location.state as AssortmentSelectionState) : ASSORTMENT_SELECTION_STATE;

    const isDesktop = useMediaQuery({ minWidth: LG });
    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const groups = useSelector((state: AppState) => state.data.orders.groupsAndProducts.groups);
    const company = useSelector((state: AppState) => state.session.user?.company);
    const mag = useSelector((state: AppState) => state.session.user?.mag);
    const products = useSelector((state: AppState) => state.data.orders.groupsAndProducts.productsInGroup);
    const localCart = useSelector((state: AppState) => (edit ? state.data.orders.localEditCart : state.data.orders.localCart));
    const advancedTree = useSelector((state: AppState) => state.data.orders.advancedTree);
    const gutterScripts = useSelector((state: AppState) => state.conf.GutterCreatorScripts);
    const isFetchingGroups = useSelector((state: AppState) => state.data.orders.props.isFetchingGroups);
    const cartContent = useSelector((state: AppState) => (edit ? state.data.orders.editCart : state.data.orders.cart));
    const productsDetails = useSelector((state: AppState) => state.data.orders.groupsAndProducts.products);
    const isProductDetailsFetching = useSelector((state: AppState) => state.data.orders.props.isProductDetailsFetching);

    const [defaultNewOrderViewDesktop, setDefaultNewOrderViewDesktop] = useState<string>();
    const [defaultNewOrderViewMobile, setDefaultNewOrderViewMobile] = useState<string>();
    const [showCartReset, setShowCartReset] = useState(!(_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)) && props.cartReset);
    const [selectedProductSymKar, setSelectedProductSymKar] = useState<string | null>(null);

    useEffect(() => {
        const params = userService.getUserParams();
        params?.forEach((param) => {
            if (param.ParamName === 'DefaultNewOrderView') {
                setDefaultNewOrderViewDesktop(param.ParamValue);
            }
            if (param.ParamName === 'DefaultNewOrderViewMobile') {
                setDefaultNewOrderViewMobile(param.ParamValue);
            }
        });
    });

    useEffect(() => {
        if (props.loadedCart && props.loadedCart.current) {
            if (!(_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)) && props.cartReset) {
                setShowCartReset(true);
            } else {
                props.setCartReset(false);
            }
        }
    }, [props.loadedCart, props.loadedCart.current]);

    const [showCart, setShowCart] = useState(false);
    const [selectedView, setSelectedView] = useState((isDesktop ? defaultNewOrderViewDesktop : defaultNewOrderViewMobile) as ViewId);

    useEffect(() => {
        setSelectedView((isDesktop ? defaultNewOrderViewDesktop : defaultNewOrderViewMobile) as ViewId);
    }, [isDesktop, defaultNewOrderViewDesktop, defaultNewOrderViewMobile]);

    const [rememberedSelectedView, setRememberedSelectedView] = useState<ViewId>();
    const [ifSelectedView, setIfSelectedView] = useState(false);

    useEffect(() => {
        if (!ifSelectedView && selectedView !== undefined) {
            setRememberedSelectedView(selectedView);
            setIfSelectedView(true);
        }
    }, [selectedView, defaultNewOrderViewDesktop, defaultNewOrderViewMobile]);

    const [selectedPath, setSelectedPath] = useState(assortmentState.selectedPath ? assortmentState.selectedPath : BASIC_GROUP);
    const [selectedProduct, setSelectedProduct] = useState((assortmentState.selectedProduct ? assortmentState.selectedProduct : null) as Product | null);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [error, setError] = useState(false);
    const [filteredTree, setFilteredTree] = useState<TreeNode[] | null>(null);
    const [findInTree, setFindInTree] = useState('');

    const inputRef = useRef<HTMLInputElement | null>(null);

    const debouncedFindInTree = useDebounce(findInTree, 300);

    const assignNewSelectedProduct = async (symKar: string, unit: string): Promise<void> => {
        if (symKar === null || unit === null) return;
        if (!_.find(productsDetails, (p) => p.symKar === symKar)) {
            await getProductWithPrice([{ symKar, unit }]);
        }

        setSelectedProductSymKar(symKar);
    };

    useEffect(() => {
        if (selectedProduct !== null && !_.some(productsDetails, { symKar: selectedProduct.symKar })) {
            getProductWithPrice([{ symKar: selectedProduct.symKar, unit: selectedProduct.unit }]);
        }
    }, [selectedProduct]);

    useEffect(() => {
        // Load history state
        const sv = ['basic', 'advanced', 'treatment', 'gutters', 'plate'].includes(history.location.state?.selectedView) ?
            history.location.state.selectedView : (rememberedSelectedView !== undefined ? rememberedSelectedView : (isDesktop ? defaultNewOrderViewDesktop : defaultNewOrderViewMobile));
        setSelectedView(sv as ViewId);
        setSelectedProduct(history.location.state?.selectedProduct ? history.location.state.selectedProduct : null);
        setSelectedPath(history.location.state?.selectedPath ? history.location.state.selectedPath : BASIC_GROUP);
    }, [history.location.state, defaultNewOrderViewDesktop, defaultNewOrderViewMobile, rememberedSelectedView]);

    useEffect(() => {
        // Fetch groups
        if (!groups && !isFetchingGroups) {
            getGroups().then((result) => setError(!result));
        }
    }, [company, mag, groups]);

    useEffect(() => {
        const cartContentMapped: CartContent = mapLocalCartToCartContents(localCart);
        if (edit) store.dispatch(dataActions.setEditCart(cartContentMapped));
        else store.dispatch(dataActions.setCart(cartContentMapped));
    }, [localCart, edit]);

    const setProduct = (product: Product | null) => {
        setSelectedProduct(product);
        if (product) {
            // if new product has been selected push it to history state
            if (!_.isEqual(product, history.location.state?.selectedProduct)) {
                history.push({
                    pathname: `${process.env.PUBLIC_URL}/new-order`,
                    state: {
                        ...history.location.state,
                        selectedPath: product.path.join('\\'),
                        selectedProduct: product
                    }
                });
            }
        }
    };

    const toggleTreeNode = (nodeKey: string) => {
        setFilteredTree((state) => (!state ? state :
            mapTree((node) => (node.key === nodeKey ? { ...node, expanded: !node.expanded } : node),
                makeRoot(state)).childrenNodes));
        setSelectedPath(nodeKey);
    };

    const getProductsBySearch = async ({ path, key }: TreeNode, comp: string | undefined) => {
        try {
            const status = await getProductsByGroup(path, comp);
            if (!status) {
                toggleTreeNode(key);
            }
        } catch (ex) {
            alert('Błąd podczas pobierania produktu');
            toggleTreeNode(key);
        }
    };

    useEffect(() => {
        if (!advancedTree) return;

        const deepCopyTree = mapTree((node: TreeNode) => {
            const isExpanded = _.isEqual(node.path, firstNGroups(selectedPath, node.level));
            // If node is expandable and contains products fetch those products
            if (isExpanded && isLeaf(node) && node.hasProducts && node.symKar === undefined && !isFetchingGroups) {
                getProductsBySearch(node, company);
            }
            // Return node with tag if node is expanded
            return ({
                ...node,
                expanded: isExpanded
            });
        }, makeRoot(advancedTree)).childrenNodes;

        const find = findInTree;
        // Search product in tree logic
        if (find) {
            const filter: (node: TreeNode) => boolean = (node: TreeNode) => _.includes(node.description.toLowerCase(), find.toLowerCase());
            const filterTree = (treeNodes: TreeNode[]): TreeNode[] => {
                const tree: TreeNode[] = [] as TreeNode[];
                treeNodes.forEach((node) => {
                    if (filter(node)) {
                        node.expanded = false;
                        tree.push(node);
                    } else {
                        const result = filterTree(node.childrenNodes);
                        if (result.length > 0) {
                            node.childrenNodes = result;
                            node.expanded = true;
                            tree.push(node);
                        }
                    }
                });
                return tree;
            };
            const fTree = filterTree(deepCopyTree);
            setFilteredTree(fTree);
            return;
        }

        setFilteredTree(deepCopyTree);
    }, [debouncedFindInTree, advancedTree]);

    useEffect(() => {
        if (selectedView !== 'advanced') return;

        // Expand all nodes for selected product or selected path after changed product or selectedView
        const path = selectedProduct ? arrayPathToString(selectedProduct.path) : selectedPath;

        setFilteredTree((state) => (!state ? state :
            mapTree((node) => (firstNGroups(path, node.level) === node.path ? { ...node, expanded: true } : node),
                makeRoot(state)).childrenNodes));
    }, [selectedView, selectedProduct]);

    const tree = useMemo(() => {
        if (!filteredTree) return null;

        const getNodeChildrenProducts = (parentNode: TreeNode) => {
            if (parentNode.symKar) {
                return [];
            }
            const path = parentNode.path.split('\\');
            const productsFromGroup = _.filter(products, (product) => product.path.toString() === path.toString());

            return _.map(productsFromGroup, (product, index) => ({
                ...product,
                key: `${product.description}_${path}_${index}`,
                childrenNodes: []
            }));
        };

        const assignProducts = (treeNodes: TreeNode[]) => {
            treeNodes.forEach((node) => {
                if (isLeaf(node)) {
                    node.childrenNodes = getNodeChildrenProducts(node);
                } else if (node.childrenNodes.length > 0 && node.hasProducts) {
                    assignProducts(node.childrenNodes);
                    node.childrenNodes.push(...getNodeChildrenProducts(node));
                } else {
                    assignProducts(node.childrenNodes);
                }
            });
        };

        return produce(filteredTree, (draft) => assignProducts(draft));
    }, [filteredTree, products]);

    const onUpdate = async (productSymKar: string, path: string[], unit?: string) => {
        setSelectedProductSymKar(productSymKar);
        let product = _.find(productsDetails, (p) => p.symKar === productSymKar) || null;
        if (unit !== undefined && !product) await getProductWithPrice([{ symKar: productSymKar, unit }]);
        product = _.find(productsDetails, (p) => p.symKar === productSymKar) || null;
        setProduct(product ?? null);
        const group = _.find(groups, (g) => g.path === arrayPathToString(path));
        if (group) {
            setSelectedPath(group.path);
            setCurrentLevel(group.groups.length - 1);
        }

        if (!isDesktop) {
            window.scrollTo(0, 600);
        }
    };

    useEffect(() => {
        if (selectedProductSymKar) {
            const product = _.find(productsDetails, (p) => p.symKar === selectedProductSymKar) || null;
            setSelectedProduct(product);
        }
    }, [productsDetails, selectedProductSymKar]);

    const focusFirstInput = () => {
        if (!inputRef) return;
        inputRef.current?.focus();
    };

    return (
        <div>
            <Cart show={showCart} close={() => setShowCart(false)} />
            <CartReset show={showCartReset} close={() => { setShowCartReset(false); props.setCartReset(false); }} />
            <Card>
                <AssortmentSelectionHeader {...{ currentLevel, selectedView, setShowCart, setSelectedView, selectedPath, selectedProduct }} isSpecialGroup={isSpecialGroup(selectedPath)} />
                <Card.Body
                    className={selectedProduct || selectedView === 'advanced' ? `AssortmentSelection-${selectedView}View` : ''}
                    style={!isDesktop || ['treatment', 'gutters', 'plate'].includes(selectedView) ? { padding: '10px' } : { minHeight: '660px' }}
                >
                    {selectedView === 'basic' && <BasicView {...{ currentLevel, selectedView, selectedPath, selectedProduct, error, assignNewSelectedProduct, setError, setCurrentLevel, setSelectedPath, setProduct, toggleTreeNode }} />}
                    {selectedView === 'advanced' && <AdvancedView {...{ error, tree, findInTree, selectedProduct, selectedPath, assignNewSelectedProduct, setFindInTree, toggleTreeNode, onUpdate, focusFirstInput }} />}
                    {
                        ['basic', 'advanced'].includes(selectedView) && selectedProduct && !isProductDetailsFetching && (
                            <>
                                <ProductSelection selectedProduct={selectedProduct} inputRef={inputRef} />
                                <SimilarProducts selectedProduct={selectedProduct} />
                                <AdditionalAssortment selectedProduct={selectedProduct} />
                            </>
                        )
                    }
                    {
                        selectedView === 'treatment' && (
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/obrobki.png`} alt='' width={1020} />
                            </div>
                        )
                    }
                    {(selectedView === 'gutters' && gutterScripts.length !== 0) ? <GutterCreator /> : ''}
                    {selectedView === 'plate' && <PlatesCreator />}
                </Card.Body>
            </Card>
            <div className={isDesktop ? 'NewOrder-footer NewOrder-footerBegin' : 'NewOrder-footer NewOrder-footerBegin d-flex justify-content-center'}>
                <ButtonComponent
                    text='PRZEJDŹ DO PODSUMOWANIA'
                    onClick={props.goToSummary}
                    onHoverAnimation={3}
                />
            </div>
        </div>
    );
};

export default AssortmentSelection;
