import ButtonComponent from 'components/ButtonComponent';
import Spinner from 'components/Spinner';
import { callArtisanCommand, getCacheManagement } from 'lib/communication/admin';
import { CacheManagement as CM, CacheManagementSource as CMSrc, CacheManagementStatus as CMSts } from 'lib/types';
import { LG, getFormattedDateWithHoursAndMinutes } from 'lib/util';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AppState } from 'reducers';

const sourceLabels = {
    zamowienia: 'Zamówienia',
    isod: 'ISOD'
} as { [key in CMSrc]: string };

const statusLabels = {
    running: '🕘',
    finished: '✔',
    error: '✘'
} as { [key in CMSts]: string };

const CacheManagement = () => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const cacheManagement = useSelector((state: AppState) => state.data.adminInfo.cacheManagement);
    const loading = useSelector((state: AppState) => state.data.adminInfo.isCacheManagementFetching);
    const [message, setMessage] = useState<string>('');
    useEffect(() => {
        getCacheManagement();
    }, []);

    const handleButtonClick = async (value: string, source: string) => {
        callArtisanCommand(value, source);
        setMessage(`Uruchomiono komendę: ${value}`);
    };

    const handleRefresh = async () => {
        getCacheManagement();
        setMessage('Odświeżono widok');
    };

    return (
        <>
            <div className='row' style={{ justifyContent: 'center', alignItems: 'baseline' }}>
                <h2>Zarządzanie pamięcią cache</h2>
                <ButtonComponent
                    text='Odśwież'
                    onClick={() => handleRefresh()}
                    onHoverAnimation={3}
                />
            </div>

            <div className='Admin-addMessage' />
            {
                loading ? <Spinner errMsg='Błąd pobierania procedur do zarządzania pamięcią cache serwera' /> : (
                    <>
                        {
                            isDesktop ? (
                                <Table bordered striped>
                                    <thead>
                                        <tr>
                                            <td>System</td>
                                            <td>Nazwa komendy</td>
                                            <td>Opis komendy</td>
                                            <td>Postęp</td>
                                            <td>Status</td>
                                            <td>Data ostatniego uruchomienia</td>
                                            <td>Czas ostatniego wykonania</td>
                                            <td>Uruchom komende</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(_.groupBy(cacheManagement, 'source'), (commands: CM[], key: CMSrc) => (
                                                <>
                                                    {_.map(commands, (cm) => (
                                                        <tr className='vertical-align-center' key={cm.id} style={commands[0] === cm ? { borderTop: '2px solid grey' } : {}}>
                                                            {commands[0] === cm && <td rowSpan={commands.length}>{sourceLabels[key]}</td>}
                                                            <td>{cm.name}</td>
                                                            <td>{cm.description}</td>
                                                            <td>
                                                                {cm.progress}
                                                                %
                                                            </td>
                                                            <td style={{ color: cm.status === 'error' ? 'red' : 'inherit' }}>{statusLabels[cm.status]}</td>
                                                            <td>{cm.date !== null ? getFormattedDateWithHoursAndMinutes(cm.date) : '-'}</td>
                                                            <td>{cm.last_execution_time !== null ? `${cm.last_execution_time} s` : '-'}</td>
                                                            <td>
                                                                <ButtonComponent
                                                                    text='Uruchom'
                                                                    onClick={() => handleButtonClick(cm.name, cm.source)}
                                                                    margin='0.0rem'
                                                                    onHoverAnimation={3}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            ) : (
                                <Table>
                                    <thead>
                                        <tr>
                                            <td colSpan={2}>System</td>
                                        </tr>
                                        <tr>
                                            <td>Nazwa komendy</td>
                                            <td>Opis komendy</td>
                                        </tr>
                                        <tr>
                                            <td>Data ostatniego uruchomienia</td>
                                            <td>Czas ostatniego wykonania</td>
                                        </tr>
                                        <tr>
                                            <td>Postęp</td>
                                            <td>Status</td>
                                        </tr>
                                    </thead>
                                    <tbody className='mobile-striped'>
                                        {
                                            _.map(_.groupBy(cacheManagement, 'source'), (commands: CM[], key: CMSrc) => (
                                                <>
                                                    {_.map(commands, (cm) => (
                                                        <>
                                                            <tr className='vertical-align-center' style={{ borderTop: '2px solid grey' }}>
                                                                <td colSpan={2}>{sourceLabels[key]}</td>
                                                            </tr>
                                                            <tr className='vertical-align-center'>
                                                                <td>{cm.name}</td>
                                                                <td>{cm.description}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{cm.date !== null ? getFormattedDateWithHoursAndMinutes(cm.date) : '-'}</td>
                                                                <td>{cm.last_execution_time !== null ? `${cm.last_execution_time} s` : '-'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {cm.progress}%
                                                                </td>
                                                                <td style={{ color: cm.status === 'error' ? 'red' : 'inherit' }}>{statusLabels[cm.status]}</td>
                                                            </tr>
                                                            <tr style={{ borderBottom: '2px solid grey' }}>
                                                                <td colSpan={2}>
                                                                    <ButtonComponent
                                                                        text='Uruchom'
                                                                        onClick={() => handleButtonClick(cm.name, cm.source)}
                                                                        margin='0.0rem'
                                                                        onHoverAnimation={3}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            )
                        }
                    </>
                )
            }
            <p>{message}</p>
        </>
    );
};

export default CacheManagement;
