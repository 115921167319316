import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaMinus } from 'react-icons/fa';

interface PrintStatusProps {
    isError?: boolean;
    errorType?: string | null;
}

const PrintStatus = ({ isError, errorType }: PrintStatusProps) => {
    const printPopover = (errorLogs: string | null) => (
        <Popover id='popover-basic' style={{ whiteSpace: 'pre-line' }}>
            <Popover.Title>Daty wystąpienia ostatnich błędów</Popover.Title>
            <Popover.Content>{errorLogs}</Popover.Content>
        </Popover>
    );

    return (
        <>
            {
                errorType === undefined ? (
                    <FaMinus style={{ color: '#afafaf' }} />
                ) : (
                    !isError ? (
                        <span className='pass-icon'>✔</span>
                    ) : (
                        <OverlayTrigger trigger='click' placement='top' overlay={printPopover(errorType)} transition>
                            <span className='error-icon'>✘</span>
                        </OverlayTrigger>
                    )
                )
            }
        </>
    );
};

export default PrintStatus;
