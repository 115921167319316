import { API_ADDRESS, get, post } from 'lib/communication';

export const getTowns = async (town: string, pna: string): Promise<string[]> => {
    const route: string = 'postal/getTowns';

    try {
        const data = await get<string[]>(`${API_ADDRESS}${route}?town=${town}&pna=${pna}`);
        if (data.ok && data.parsedBody) {
            return data.parsedBody;
        }
        return [];
    } catch (err) {
        console.log('Error ', err);
        return [];
    }
};

export const getPnas = async (town: string, pna: string): Promise<string[]> => {
    const route: string = 'postal/getPnas';

    try {
        const data = await get<string[]>(`${API_ADDRESS}${route}?town=${town}&pna=${pna}`);
        if (data.ok && data.parsedBody) {
            return data.parsedBody;
        }
        return [];
    } catch (err) {
        console.log('Error ', err);
        return [];
    }
};

export const updatePostals = async (files: File[]) => {
    const route: string = 'postal/updatePostals';

    try {
        const formData = new FormData();
        files.forEach((f) => {
            formData.append('postalsFiles[]', f);
        });
        const args: RequestInit = { method: 'post', body: formData };
        const data = await post<string>(`${API_ADDRESS}${route}`, formData, args);
        if (data.ok && data.parsedBody) return data.parsedBody;
        return 'Wystąpił błąd podczas próby aktualizacji bazy kodów pocztowych.';
    } catch (err) {
        console.log('Error ', err);
        return 'Wystąpił błąd podczas próby aktualizacji bazy kodów pocztowych.';
    }
};
