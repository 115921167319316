import './DeliveryAddressForm.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { TownOptionType, UserDeliveryAddress } from 'lib/types';
import {
    MAX_ADDRESS_STREET_NAME_LENGTH, MAX_ADDRESS_HOUSE_NUMBER_LENGTH, MAX_ADDRESS_APARTMENT_NUMBER_LENGTH,
    MAX_ADDRESS_CONTACT_LENGTH, MAX_ADDRESS_PHONE_NUMBER_LENGTH, MAX_ADDRESS_TOWN_LENGTH, MAX_ADDRESS_DESC_LENGTH
} from 'lib/util';
import ButtonComponent from 'components/ButtonComponent';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { Countries, StreetTypes } from './DeliveryAddressData.json';
import Dropdown from 'components/Dropdown/Dropdown';
import Select from 'react-select';
import { getPnas, getTowns } from 'lib/communication/postal';
import useDebounce from 'lib/hooks/useDebounce';
import CustomMenuList from 'components/CustomMenuList/CustomMenuList';

type CountryOptionType = typeof Countries[0];

const getCountry = (country: string) => (
    Countries.find((val) => val.value === country) as CountryOptionType | undefined
);

type StreetTypeOptionType = typeof StreetTypes[0];

const getStreetType = (streetType: string) => (
    StreetTypes.find((val) => val.value === streetType) as StreetTypeOptionType | undefined
);

type FormValidationError = 'empty' | 'pattern';

class FormValidationSummary {
    streetName: FormValidationError | null = null;
    houseNumber: FormValidationError | null = null;
    zipCode: FormValidationError | null = null;
    town: FormValidationError | null = null;
    country: FormValidationError | null = null;
    phoneNumber: FormValidationError | null = null;
    constructor(init?: Partial<FormValidationSummary>) {
        Object.assign(this, init);
    }
    anyError() {
        if (this.streetName || this.houseNumber || this.zipCode || this.town || this.country || this.phoneNumber) {
            return true;
        }
        return false;
    }
}

interface DeliveryAddressFormModalProps {
    onCancel: () => void,
    onAccept: (address: UserDeliveryAddress) => void,
    show: boolean,
    editData?: UserDeliveryAddress
}

const DeliveryAddressFormModal = (props: DeliveryAddressFormModalProps) => {
    const [validated, setValidated] = useState<FormValidationSummary>(new FormValidationSummary());
    const [edit, setEdit] = useState<boolean>(props.editData?.id !== undefined);
    const [fullAddress, setFullAddress] = useState<boolean>(props.editData?.fullAddress ?? true);
    const [streetType, setStreetType] = useState<StreetTypeOptionType | null>(getStreetType(props.editData?.streetType ?? StreetTypes[0].value) ?? StreetTypes[0]);
    const [streetName, setStreetName] = useState<string>(props.editData?.streetName ?? '');
    const [houseNumber, setHouseNumber] = useState<string>(props.editData?.houseNumber ?? '');
    const [apartmentNumber, setapartmentNumber] = useState<string>(props.editData?.apartmentNumber ?? '');
    const [zipCode, setZipCode] = useState<string>(props.editData?.zipCode ?? '');
    const [town, setTown] = useState<string>(props.editData?.town ?? '');
    const [townPL, setTownPL] = useState<TownOptionType>({ value: '', label: '' });
    const [country, setCountry] = useState<CountryOptionType>(getCountry(props.editData?.country ?? Countries[0].value) ?? Countries[0]);
    const [contact, setContact] = useState<string>(props.editData?.contact ?? '');
    const [phoneNumber, setPhoneNumber] = useState<string>(props.editData?.phoneNumber ?? '');
    const [description, setDescription] = useState<string>(props.editData?.description ?? '');
    const [townsList, setTownsList] = useState<TownOptionType[]>([]);
    const [searchTown, setSearchTown] = useState<string>('');
    const [zipCodesList, setZipCodesList] = useState<TownOptionType[]>([]);
    const [searchZipCode, setSearchZipCode] = useState<string>('');
    const [townsListLoading, setTownsListLoading] = useState<boolean>(false);
    const [zipCodesListLoading, setZipCodesListLoading] = useState<boolean>(false);

    const debouncedSearchTown = useDebounce(searchTown, 500);
    const debouncedSearchZipCode = useDebounce(searchZipCode, 500);

    const visibleStreetType = useMemo(() => (fullAddress ? streetType : null), [fullAddress, streetType]);
    const visibleStreetName = useMemo(() => (fullAddress ? streetName : ''), [fullAddress, streetName]);
    const visibleHouseNumber = useMemo(() => (fullAddress ? houseNumber : ''), [fullAddress, houseNumber]);
    const visibleApartmentNumber = useMemo(() => (fullAddress ? apartmentNumber : ''), [fullAddress, apartmentNumber]);

    const loadTownsList = async (townName: string, pna: string, setIfOnlyOne: boolean = false) => {
        setTownsListLoading(true);

        const data = await getTowns(townName, pna);
        const towns = data.map((t) => ({
            value: t.split('(')[0].trim(),
            label: t
        }));
        setTownsList(towns);
        if (setIfOnlyOne && towns.length === 1) setTownPL(towns[0]);

        setTownsListLoading(false);
    };

    const loadZipCodesList = async (townName: string, pna: string, setIfOnlyOne: boolean = false) => {
        setZipCodesListLoading(true);

        const data = await getPnas(townName, pna);
        const zips = data.map((t) => ({
            value: t,
            label: t
        }));
        setZipCodesList(zips);
        if (setIfOnlyOne && zips.length === 1) setZipCode(zips[0].value);

        setZipCodesListLoading(false);
    };

    useEffect(() => {
        setValidated(new FormValidationSummary());
        setEdit(props.editData?.id !== undefined);
        setFullAddress(props.editData?.fullAddress ?? true);
        setStreetType(getStreetType(props.editData?.streetType ?? StreetTypes[0].value) ?? null);
        setStreetName(props.editData?.streetName ?? '');
        setHouseNumber(props.editData?.houseNumber ?? '');
        setapartmentNumber(props.editData?.apartmentNumber ?? '');
        setZipCode(props.editData?.zipCode ?? '');
        setTown(props.editData?.town ?? '');
        setCountry(getCountry(props.editData?.country ?? Countries[0].value) ?? Countries[0]);
        setContact(props.editData?.contact ?? '');
        setPhoneNumber(props.editData?.phoneNumber ?? '');
        setDescription(props.editData?.description ?? '');

        if ((getCountry(props.editData?.country ?? Countries[0].value) ?? Countries[0]).value === 'PL' && props.editData?.town && props.editData?.zipCode) {
            loadTownsList(props.editData.town, props.editData.zipCode, true);
        }
    }, [props.editData]);

    const getFormUserDeliveryAddress = () => ({
        id: props.editData?.id,
        streetType: visibleStreetType?.value ?? '',
        streetName: visibleStreetName,
        houseNumber: visibleHouseNumber,
        apartmentNumber: visibleApartmentNumber,
        zipCode,
        town: country.value === 'PL' ? townPL.value : town,
        country: country.value,
        contact,
        phoneNumber: parsePhoneNumber(phoneNumber, { defaultCountry: 'PL', extract: false })!.number as string,
        description,
        special: false,
        readOnly: false,
        fullAddress
    } as UserDeliveryAddress);

    const resetFormUserDeliveryAddress = () => {
        setValidated(new FormValidationSummary());
        setEdit(false);
        setStreetType(StreetTypes[0]);
        setStreetName('');
        setHouseNumber('');
        setapartmentNumber('');
        setZipCode('');
        setTown('');
        setCountry(Countries[0]);
        setContact('');
        setPhoneNumber('');
        setDescription('');
    };

    const cancel = () => {
        props.onCancel();
        resetFormUserDeliveryAddress();
    };

    const accept = () => {
        props.onAccept(getFormUserDeliveryAddress());
        resetFormUserDeliveryAddress();
    };

    const checkFormValidity = () => {
        const summary = new FormValidationSummary();

        if (fullAddress && streetName === '') {
            summary.streetName = 'empty';
        }
        if (fullAddress && houseNumber === '') {
            summary.houseNumber = 'empty';
        }
        if (zipCode === '') {
            summary.zipCode = 'empty';
        } else if (!(new RegExp(country.regex).test(zipCode))) {
            summary.zipCode = 'pattern';
        }
        if (country.value === 'PL' && townPL.value === '') {
            summary.town = 'empty';
        }
        if (country.value !== 'PL' && town === '') {
            summary.town = 'empty';
        }
        if (country.value === '') {
            summary.country = 'empty';
        }
        if (phoneNumber === '') {
            summary.phoneNumber = 'empty';
        } else if (!isValidPhoneNumber(phoneNumber, { defaultCountry: 'PL' }) ||
            parsePhoneNumber(phoneNumber, { defaultCountry: 'PL', extract: false }) === undefined) {
            summary.phoneNumber = 'pattern';
        }

        return summary;
    };

    // remove validation error status after updating the value once
    useEffect(() => {
        setValidated((v) => new FormValidationSummary({ ...v, streetName: null }));
    }, [streetName, fullAddress]);

    useEffect(() => {
        setValidated((v) => new FormValidationSummary({ ...v, houseNumber: null }));
    }, [houseNumber, fullAddress]);

    useEffect(() => {
        setValidated((v) => new FormValidationSummary({ ...v, zipCode: null }));
        if (country.value === 'PL') {
            if (zipCode === '') {
                setTownsList([]);
            } else {
                loadTownsList('', zipCode, true);
            }
        }
    }, [zipCode]);

    useEffect(() => {
        setValidated((v) => new FormValidationSummary({ ...v, town: null }));
    }, [town]);

    useEffect(() => {
        if (country.value === 'PL') {
            if (!townPL || townPL.label === '') {
                setZipCodesList([]);
            } else {
                loadZipCodesList(townPL.label, '', true);
            }
        }
    }, [townPL]);

    useEffect(() => {
        setValidated((v) => new FormValidationSummary({ ...v, country: null }));
    }, [country]);

    useEffect(() => {
        setValidated((v) => new FormValidationSummary({ ...v, phoneNumber: null }));
    }, [phoneNumber]);

    useEffect(() => {
        if (debouncedSearchTown.length < 3 && zipCode === '') {
            setTownsList([]);
            return;
        }

        if (debouncedSearchTown.length >= 3) {
            loadTownsList(debouncedSearchTown, zipCode);
        }
    }, [debouncedSearchTown]);

    useEffect(() => {
        if (debouncedSearchZipCode.length < 2 && (townPL === null || townPL.label === '')) {
            setZipCodesList([]);
            return;
        }

        if (debouncedSearchZipCode.length >= 2) {
            loadZipCodesList(townPL?.label ?? '', debouncedSearchZipCode);
        }
    }, [debouncedSearchZipCode]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const validation = checkFormValidity();
        setValidated(validation);
        if (validation.anyError()) { return; }
        accept();
    };

    return (
        <Modal show={props.show} onHide={cancel} centered size='lg' className='DeliveryAddressModal-form'>
            <Modal.Header closeButton>
                <Modal.Title>PROSZĘ UZUPEŁNIĆ DANE ADRESOWE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId='AddressStreet'>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={3} xs={12}>
                                <Dropdown
                                    options={StreetTypes}
                                    placeholder='Typ ulicy'
                                    isSearchable={false}
                                    isClearable
                                    isDisabled={!fullAddress}
                                    value={visibleStreetType}
                                    onChange={(option) => setStreetType(option as StreetTypeOptionType ?? null)}
                                />
                                <Row className='label-row'>
                                    <Form.Label>Opcjonalne</Form.Label>
                                    <Form.Label>&nbsp;</Form.Label>
                                </Row>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={9} xs={12}>
                                <Form.Control
                                    type='text'
                                    placeholder='Nazwa ulicy'
                                    maxLength={MAX_ADDRESS_STREET_NAME_LENGTH}
                                    disabled={!fullAddress}
                                    value={visibleStreetName}
                                    onChange={(event) => setStreetName(event.target.value)}
                                />
                                <Row className='label-row'>
                                    <Form.Label style={{ color: 'red' }}>{validated.streetName === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
                                    <Form.Label style={{ color: streetName.length === MAX_ADDRESS_STREET_NAME_LENGTH ? 'red' : 'inherit' }}>
                                        {visibleStreetName.length} / {MAX_ADDRESS_STREET_NAME_LENGTH}
                                    </Form.Label>
                                </Row>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Form.Control
                                    type='text'
                                    placeholder='Nr budynku'
                                    maxLength={MAX_ADDRESS_HOUSE_NUMBER_LENGTH}
                                    disabled={!fullAddress}
                                    value={visibleHouseNumber}
                                    onChange={(event) => setHouseNumber(event.target.value)}
                                />
                                <Row className='label-row'>
                                    <Form.Label>
                                        <Form.Check
                                            className='full-address-check'
                                            type='checkbox'
                                            label='Brak ulicy'
                                            checked={!fullAddress}
                                            onChange={(event) => setFullAddress(!(event.target as HTMLInputElement).checked)}
                                        />
                                    </Form.Label>
                                    <Form.Label style={{ color: 'red' }}>{validated.houseNumber === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
                                    <Form.Label style={{ color: houseNumber.length === MAX_ADDRESS_HOUSE_NUMBER_LENGTH ? 'red' : 'inherit' }}>
                                        {visibleHouseNumber.length} / {MAX_ADDRESS_HOUSE_NUMBER_LENGTH}
                                    </Form.Label>
                                </Row>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Form.Control
                                    type='text'
                                    placeholder='Nr mieszkania'
                                    maxLength={MAX_ADDRESS_APARTMENT_NUMBER_LENGTH}
                                    disabled={!fullAddress}
                                    value={visibleApartmentNumber}
                                    onChange={(event) => setapartmentNumber(event.target.value)}
                                />
                                <Row className='label-row'>
                                    <Form.Label>Opcjonalne</Form.Label>
                                    <Form.Label style={{ color: apartmentNumber.length === MAX_ADDRESS_APARTMENT_NUMBER_LENGTH ? 'red' : 'inherit' }}>
                                        {visibleApartmentNumber.length} / {MAX_ADDRESS_APARTMENT_NUMBER_LENGTH}
                                    </Form.Label>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId='AddressCountryAndCity'>
                        <Row>
                            <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                <Dropdown
                                    options={Countries}
                                    placeholder='Kraj'
                                    isSearchable={false}
                                    value={country}
                                    onChange={(option) => setCountry(option as CountryOptionType)}
                                />
                                <Row className='label-row'>
                                    <Form.Label style={{ color: 'red' }}>{validated.country === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
                                    <Form.Label>&nbsp;</Form.Label>
                                </Row>
                            </Col>
                            <Col xl={5} lg={5} md={8} sm={8} xs={12}>
                                {country.value === 'PL' ? <Select
                                    isClearable
                                    required
                                    placeholder='Miejscowość'
                                    noOptionsMessage={() => 'Nie znaleziono miejscowości'}
                                    isLoading={townsListLoading}
                                    onChange={(e) => { setTownPL(e as TownOptionType); }}
                                    onInputChange={(e) => { setSearchTown(e); }}
                                    options={townsList}
                                    value={townPL}
                                /> : <Form.Control
                                    type='text'
                                    placeholder='Miejscowość'
                                    maxLength={MAX_ADDRESS_TOWN_LENGTH}
                                    value={town}
                                    onChange={(event) => setTown(event.target.value)}
                                />}
                                <Row className='label-row'>
                                    <Form.Label style={{ color: 'red' }}>{validated.town === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
                                    <Form.Label style={{ color: (country.value === 'PL' ? (townPL?.value.length ?? 0) : town.length) === MAX_ADDRESS_TOWN_LENGTH ? 'red' : 'inherit' }}>
                                        {country.value === 'PL' ? (townPL?.value.length ?? '0') : town.length} / {MAX_ADDRESS_TOWN_LENGTH}
                                    </Form.Label>
                                </Row>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                                {country.value === 'PL' ? <Select
                                    isClearable
                                    required
                                    placeholder='Kod pocztowy'
                                    noOptionsMessage={() => 'Nie znaleziono kodu'}
                                    isLoading={zipCodesListLoading}
                                    value={{ label: zipCode, value: zipCode }}
                                    onChange={(e) => { setZipCode(e ? (e as TownOptionType).value : ''); }}
                                    onInputChange={(e) => { setSearchZipCode(e); }}
                                    options={zipCodesList}
                                    components={{
                                        MenuList: CustomMenuList
                                    }}
                                /> : <Form.Control
                                    type='text'
                                    placeholder='Kod pocztowy'
                                    maxLength={country.length}
                                    value={zipCode}
                                    onChange={(event) => setZipCode(event.target.value)}
                                />}
                                <Row className='label-row'>
                                    {validated.zipCode === null && <Form.Label>{country.format}</Form.Label>}
                                    {validated.zipCode === 'empty' && <Form.Label style={{ color: 'red' }}>Pole obowiązkowe</Form.Label>}
                                    {validated.zipCode === 'pattern' && <Form.Label style={{ color: 'red' }}>Nieprawidłowy kod</Form.Label>}
                                    <Form.Label style={{ color: zipCode.length === country.length ? 'green' : 'inherit' }}>
                                        {zipCode.length} / {country.length}
                                    </Form.Label>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId='AddressDescription'>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Form.Control
                                    type='text'
                                    placeholder='Osoba kontaktowa'
                                    maxLength={MAX_ADDRESS_CONTACT_LENGTH}
                                    value={contact}
                                    onChange={(event) => setContact(event.target.value)}
                                />
                                <Row className='label-row'>
                                    <Form.Label>Opcjonalne</Form.Label>
                                    <Form.Label style={{ color: contact.length === MAX_ADDRESS_CONTACT_LENGTH ? 'red' : 'inherit' }}>
                                        {contact.length} / {MAX_ADDRESS_CONTACT_LENGTH}
                                    </Form.Label>
                                </Row>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Form.Control
                                    type='text'
                                    placeholder='Nr telefonu'
                                    maxLength={MAX_ADDRESS_PHONE_NUMBER_LENGTH}
                                    value={phoneNumber}
                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                />
                                <Row className='label-row'>
                                    {validated.phoneNumber === null && <Form.Label>&nbsp;</Form.Label>}
                                    {validated.phoneNumber === 'empty' && <Form.Label style={{ color: 'red' }}>Pole obowiązkowe</Form.Label>}
                                    {validated.phoneNumber === 'pattern' && <Form.Label style={{ color: 'red' }}>Nieprawidłowy nr telefonu</Form.Label>}
                                    <Form.Label>&nbsp;</Form.Label>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Form.Control
                                    as='textarea'
                                    placeholder='Opis'
                                    rows={3}
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                />
                                <Row className='label-row'>
                                    <Form.Label>Opcjonalne</Form.Label>
                                    <Form.Label style={{ color: description.length === MAX_ADDRESS_DESC_LENGTH ? 'red' : 'inherit' }}>
                                        {description.length} / {MAX_ADDRESS_DESC_LENGTH}
                                    </Form.Label>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Row>
                        <ButtonComponent
                            marginLeft='auto'
                            variant='danger'
                            text={edit ? 'Anuluj edycję' : 'Anuluj'}
                            onClick={cancel}
                            onHoverAnimation={3}
                        />
                        <ButtonComponent
                            variant='success'
                            type='submit'
                            text={edit ? 'Akceptuj edycję' : 'Dodaj nowy adres'}
                            onHoverAnimation={3}
                        />
                    </Form.Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default DeliveryAddressFormModal;
