import React, { useEffect, useState } from 'react';
import { Card, ListGroup, ListGroupItem, Modal, Spinner as ReactSpinner } from 'react-bootstrap';
import { AppState, dataActions, useUser } from 'reducers';
import store from 'store';
import { useSelector } from 'react-redux';
import CartTable from 'components/CartTable';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Spinner from 'components/Spinner';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import ButtonComponent from 'components/ButtonComponent';
import { DetailedOrder, RooferInfo, ProductInOrder, UserDeliveryAddress } from 'lib/types';
import NewOrderForm from './NewOrderForm';
import { getRoofer } from 'lib/communication/userInfo';
import { addOrder, appendOrder, modifyOrder } from 'lib/communication/orders';
import { getDefaultUnitOfProduct } from 'lib/utilityMetods';

interface NewOrderSummaryProps {
    goToAssortmentSelection: () => void;
    goToSummary: () => void;
    goToOrderForm: () => void;
    detailedOrder: DetailedOrder;
    completeAction: () => void;
    setIsCancelModeOn: (isCancelModeOn: boolean) => void;
    isCancelModeOn: boolean;
}

const NewOrderSummary = (props: NewOrderSummaryProps) => {
    const history = useHistory();
    const [step, setStep] = useState(2);
    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const append = useSelector((state: AppState) => state.data.orders.append);
    const appendNagIdSource = useSelector((state: AppState) => state.data.orders.appendNagIdSource);
    const cartContent = useSelector((state: AppState) => (edit ? state.data.orders.editCart : state.data.orders.cart));
    const isFetching = useSelector((state: AppState) => state.data.orders.props.isFetching);
    const error = useSelector((state: AppState) => state.data.orders.props.isErrorGettingDetailedOrder);
    const userDeliveryAddresses = useSelector((state: AppState) => state.data.userInfo.userDeliveryAddresses); // addresses should be fetched in an earlier view
    const isDesktop = useMediaQuery({ minWidth: LG });
    const user = useUser();

    const [deliveryAddress, setDeliveryAddress] = useState<UserDeliveryAddress | undefined>(props.detailedOrder?.address || undefined);
    const [customerOrderNumber, setCustomerOrderNumber] = useState(props.detailedOrder?.customerOrderNumber || '');
    const [orderId, setOrderId] = useState(edit ? props.detailedOrder?.orderId || '' : '');
    const [orderNumber, setOrderNumber] = useState(edit ? props.detailedOrder?.orderNumber || '' : '');
    const [orderDesc, setOrderDesc] = useState(props.detailedOrder?.description || '');
    const [clientDesc, setClientDesc] = useState(props.detailedOrder?.customerDesc || '');
    const [rooferLogin, setRooferLogin] = useState(props.detailedOrder?.rooferLogin || '');

    const [rooferInfo, setRooferInfo] = useState(<span>Po podaniu poprawnego loginu wyświetlone zostaną dane w celu weryfikacji</span> as JSX.Element);
    const [addToExisting, setAddToExisting] = useState(edit ? props.detailedOrder?.addToExisting || false : false);
    const [rooferLoginCase, setRooferLoginCase] = useState(0);
    const [message, setMessage] = useState('defaultMessage');
    const [nextPage, setNextPage] = useState(false);
    const [show, setShow] = useState(false);
    const [orderBtnClicked, setOrderBtnClicked] = useState(false);
    const [addresses, setAddresses] = useState<UserDeliveryAddress[]>([]);
    const localCart = useSelector((state: AppState) => (edit ? state.data.orders.localEditCart : state.data.orders.localCart));
    const isAddingOrder = useSelector((state: AppState) => state.data.orders.props.isAddingOrder);

    // prepare addresses to show in the select
    useEffect(() => {
        let address = userDeliveryAddresses ?? [];
        if (!_.isEmpty(props.detailedOrder) && props.detailedOrder.address !== undefined) { // if order fetched and not null
            if (!_.some(userDeliveryAddresses, (addr) => addr.id === props.detailedOrder.address?.id)) { // if address is not on the list
                const addr = { ...props.detailedOrder.address, readOnly: true }; // if its not on the list then it definetely cant be edited
                address = [addr, ...address]; // add address to the list
            }
        }
        setAddresses(address);
    }, [props.detailedOrder, userDeliveryAddresses]);

    useEffect(() => {
        setDeliveryAddress(props.detailedOrder?.address || undefined);
        setCustomerOrderNumber(props.detailedOrder?.customerOrderNumber || '');
        setOrderId(edit ? props.detailedOrder?.orderId || '' : '');
        setOrderNumber(edit ? props.detailedOrder?.orderNumber || '' : '');
        setOrderDesc(props.detailedOrder?.description || '');
        setClientDesc(props.detailedOrder?.customerDesc || '');
        setRooferLogin(props.detailedOrder?.rooferLogin || '');
        setAddToExisting(edit ? props.detailedOrder?.addToExisting || false : false);
    }, [props.detailedOrder]);

    useEffect(() => {
        if (rooferLoginCase) {
            setRooferLoginCase(0);
            setRooferInfo(<span>Po podaniu poprawnego loginu wyświetlone zostaną dane w celu weryfikacji</span>);
        }
    }, [rooferLogin]);

    const changeCancelOrder = () => {
        props.setIsCancelModeOn(!props.isCancelModeOn);
    };

    const assortmentSelectionState = () => {
        setStep(1);
        props.goToAssortmentSelection();
    };

    const summaryState = () => {
        setStep(2);
        props.goToSummary();
    };

    const orderState = () => {
        setStep(3);
        props.goToOrderForm();
    };

    if (isFetching || error) {
        return <Spinner showError={error} />;
    }

    const verifyRoofer = async (): Promise<boolean> => {
        // Returns true if login is roofer is verified or empty else false

        // If user left empty input then returns true
        if (!rooferLogin) {
            setRooferLoginCase(0);

            setRooferInfo(<span>Po podaniu poprawnego loginu wyświetlone zostaną dane w celu weryfikacji</span>);

            return true;
        }

        const info: RooferInfo = await getRoofer(rooferLogin);

        // If rooferInfo has key error then returns false (it's invalid login case)
        if (info.error) {
            setRooferLoginCase(1);
            setRooferInfo(<span className='invalid-roofer-login-info'>Nie znaleziono dekarza pasującego do podanego loginu! Wpisz poprawny login dekarza lub pozostaw pole puste.</span>);
            return false;
        }

        // If there is serverError then returns false
        if (info.serverError) {
            setRooferLoginCase(1);
            setRooferInfo(<span className='invalid-roofer-login-info'>Napotkano błąd przy pobieraniu danych z serwera. Spróbuj ponownie za chwilę!</span>);
            return false;
        }

        // Else sets roofer info and returns true (it's valid login case)
        setRooferLoginCase(2);

        const phoneNo = info.phone.replace(/\d{6}(\d{3})/, '*** *** $1');
        setRooferInfo(
            <div className='roofer-info'>
                <p>
                    Dekarz:
                    <b>{info.login}</b>
                </p>
                <p>
                    Nazwa firmy:
                    <b>{info.company}</b>
                </p>
                <p>
                    NIP:
                    <b>{info.nip}</b>
                </p>
                {' '}
                <p>
                    Telefon:
                    <b>{phoneNo}</b>
                </p>
            </div>
        );

        return true;
    };

    const validateOrder = async () => {
        let isValid = true;

        setRooferLoginCase(0);
        setRooferInfo(<span>Po podaniu poprawnego loginu wyświetlone zostaną dane w celu weryfikacji</span>);

        if (!(await verifyRoofer())) {
            isValid = false;
        }

        return isValid;
    };

    const sendOrder = async () => {
        const products: ProductInOrder[] = [];
        _.map(localCart.productGroups, (pg) => _.map([...pg.mainProducts, ...pg.additionalProducts], (p) => {
            products.push({
                product: p.product,
                quantity: p.quantity * (p.unit?.converter ?? 1),
                dimension: p.dimension,
                additionsSelected: p.additionsSelected,
                unit: getDefaultUnitOfProduct(p.product).unit
            });
        }));
        _.map(localCart.gutterings, (gutter) => products.push({
            product: gutter.product,
            quantity: gutter.quantity,
            dimension: null,
            additionsSelected: null,
            unit: getDefaultUnitOfProduct(gutter.product).unit
        }));
        _.map(localCart.isod, (isodGroup) => _.map(isodGroup, (product) => products.push({
            product: product.product,
            quantity: product.quantity,
            dimension: product.dimension,
            additionsSelected: product.additionsSelected,
            unit: getDefaultUnitOfProduct(product.product).unit
        })));

        const added = edit ?
            await modifyOrder({
                orderId,
                address: deliveryAddress,
                customerDesc: clientDesc,
                description: orderDesc,
                addToExisting,
                rooferLogin,
                orderNumber,
                products,
                customerOrderNumber
            }) : (
                append ? (
                    await appendOrder({
                        orderId,
                        address: deliveryAddress,
                        customerDesc: clientDesc,
                        description: orderDesc,
                        addToExisting,
                        rooferLogin,
                        products,
                        customerOrderNumber
                    }, appendNagIdSource ?? '', cartContent.summaryPrice)
                ) : (
                    await addOrder({
                        orderId,
                        address: deliveryAddress,
                        customerDesc: clientDesc,
                        description: orderDesc,
                        addToExisting,
                        rooferLogin,
                        products,
                        customerOrderNumber
                    }, cartContent.summaryPrice)
                ));

        if (added) {
            if (edit) setMessage('Zamówienie zostało pomyślnie zmodyfikowane');
            else if (append) setMessage('Domówienie do zamówienia zostało pomyślnie złożone');
            else setMessage('Zamówienie zostało pomyślnie złożone');
            store.dispatch(dataActions.setDetailedOrder({} as DetailedOrder));
            setNextPage(true);
            setShow(true);
        }
    };

    const handleSubmit = async (event: React.MouseEvent) => {
        if (!orderBtnClicked) {
            setOrderBtnClicked(true);
            event.stopPropagation();
            if (await validateOrder()) {
                sendOrder();
            } else {
                setOrderBtnClicked(false);
            }
        }
    };

    const newOrderForm = (<NewOrderForm
        completeAction={props.completeAction}
        {...{
            deliveryAddress,
            setDeliveryAddress,
            addresses,
            customerOrderNumber,
            setCustomerOrderNumber,
            orderNumber,
            orderDesc,
            setOrderDesc,
            clientDesc,
            setClientDesc,
            rooferLogin,
            setRooferLogin,
            rooferInfo,
            rooferLoginCase,
            addToExisting,
            setAddToExisting,
            verifyRoofer,
            handleSubmit,
            message,
            nextPage,
            show,
            setShow
        }}
    />);

    return (
        <div className='NewOrder-summarySection'>
            {isAddingOrder && <div className='NewOrder-summary-addingOrder-overlay'></div>}
            <div className='NewOrder-summaryLeftBar'>
                <Modal show={props.isCancelModeOn} onHide={changeCancelOrder} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Uwaga</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: 'center' }}>
                            {edit ? 'Czy na pewno chcesz anulować edycję zamówienia?' : (
                                <>
                                    <p>Czy na pewno chcesz anulować zamówienie?</p>
                                    <p>Spowoduje to wyczyszczenie koszyka</p>
                                </>
                            )}
                        </div>
                        <div className='NewOrder-summaryCancelConfirm-buttons'>
                            <ButtonComponent
                                marginLeft='0'
                                marginRight='0'
                                width='45%'
                                text='TAK'
                                onClick={() => {
                                    store.dispatch(dataActions.setDetailedOrder({} as DetailedOrder));
                                    if (edit) {
                                        history.push(`${process.env.PUBLIC_URL}/orders`);
                                        store.dispatch(dataActions.clearLocalEditCart());
                                        store.dispatch(dataActions.setEdit(false));
                                    } else {
                                        if (append) {
                                            store.dispatch(dataActions.setAppend(false));
                                        }
                                        assortmentSelectionState();
                                        store.dispatch(dataActions.clearLocalCart());
                                    }
                                    changeCancelOrder();
                                }}
                                onHoverAnimation={3}
                            />
                            <ButtonComponent
                                marginLeft='10%'
                                marginRight='0'
                                width='45%'
                                text='NIE'
                                onClick={changeCancelOrder}
                                onHoverAnimation={3}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
                <Card className='NewOrder-summaryCard'>
                    <Card.Header>
                        Podsumowanie zamówienia
                    </Card.Header>
                    <Card.Body>
                        <ListGroup className='list-group-flush'>
                            <ListGroupItem className='NewOrder-summary NewOrder-summaryOrder'>
                                <Card.Title className='NewOrder-summaryClientData'>PODSUMOWANIE</Card.Title>
                                <Card.Subtitle className='NewOrder-summaryClientSubtitle'>
                                    <span>Twoje produkty w koszyku:</span>
                                    <span>{cartContent.itemsCount}</span>
                                </Card.Subtitle>
                                <hr style={{ marginTop: '5px' }} />
                                <div className='NewOrder-summaryLabel'>WARTOŚĆ ZAMÓWIENIA:</div>
                                <div className='NewOrder-summaryValue'>{cartContent.summaryPriceBeforeDiscount?.toFixed(2)}</div>
                                <div className='NewOrder-summaryLabel'>RABAT:</div>
                                <div className='NewOrder-summaryValue'>
                                    -
                                    {cartContent.summaryDiscont?.toFixed(2)}
                                </div>
                                <div className='NewOrder-summaryLabel'>VAT:</div>
                                <div className='NewOrder-summaryValue'>{cartContent.summaryVat?.toFixed(2)}</div>
                            </ListGroupItem>
                            <ListGroupItem className='NewOrder-summary'>
                                <div className='NewOrder-summaryLabel'><b>SUMA:</b></div>
                                <div className='NewOrder-summaryValue'>
                                    <b>
                                        {cartContent.summaryPrice?.toFixed(2)}
                                        {' '}
                                        (
                                        {user?.currency}
                                        )
                                    </b>
                                </div>
                            </ListGroupItem>
                        </ListGroup>
                    </Card.Body>
                </Card>
                {isDesktop && <ButtonComponent
                    marginLeft='0'
                    marginRight='0'
                    marginTop='5px'
                    marginBottom='0'
                    width='100%'
                    text={step === 2 ? (edit ? 'DODAJ PRODUKTY' : 'POWRÓT DO WYBORU ASORTYMENTU') : 'POWRÓT DO PODSUMOWANIA'}
                    onClick={step === 2 ? () => assortmentSelectionState() : () => summaryState()}
                />}
                {isDesktop && (
                    <div className='NewOrder-summary-buttons'>
                        <ButtonComponent
                            text={edit ? 'ANULUJ EDYCJĘ ' : 'ANULUJ ZAMÓWIENIE'}
                            marginLeft='0'
                            marginRight='0'
                            marginTop='5px'
                            variant='danger'
                            onClick={changeCancelOrder}
                            visibility={step === 3 ? 'hidden' : 'visible'}
                            brandColoredButton={false}
                        />
                        <ButtonComponent
                            text={isAddingOrder ? undefined : (edit ? 'ZATWIERDŹ EDYCJĘ' : (append ? 'WYSTAW DOMÓWIENIE' : 'ZŁÓŻ ZAMÓWIENIE'))}
                            marginLeft='0'
                            marginRight='0'
                            marginTop='5px'
                            variant='success'
                            onClick={step === 3 ? handleSubmit : orderState}
                            disabled={_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)}
                            brandColoredButton={false}
                        >
                            {isAddingOrder ? <ReactSpinner animation='border' style={{ verticalAlign: 'middle', margin: 0 }} /> : undefined}
                        </ButtonComponent>
                    </div>
                )}
            </div>
            <div className='NewOrder-summaryProducts'>
                {step === 2 ? <CartTable /> : newOrderForm}
            </div>
            {!isDesktop && (
                <div className='NewOrder-footer NewOrder-summary-footer'>
                    <ButtonComponent
                        marginTop='2px'
                        marginBottom='2px'
                        marginLeft='0'
                        marginRight='0'
                        height='54px'
                        width='32%'
                        text={step === 2 ? (edit ? 'DODAJ PRODUKTY' : 'KONTYNUUJ ZAMÓWIENIE') : 'POWRÓT'}
                        onClick={step === 2 ? () => assortmentSelectionState() : () => summaryState()}
                    />
                    <ButtonComponent
                        marginTop='2px'
                        marginBottom='2px'
                        marginLeft='0'
                        marginRight='0'
                        height='54px'
                        width='32%'
                        variant='danger'
                        text={edit ? 'ANULUJ EDYCJĘ ' : 'ANULUJ ZAMÓWIENIE'}
                        onClick={changeCancelOrder}
                        visibility={step === 3 ? 'hidden' : 'visible'}
                        brandColoredButton={false}
                    />
                    <ButtonComponent
                        marginTop='2px'
                        marginBottom='2px'
                        marginLeft='0'
                        marginRight='0'
                        height='54px'
                        width='32%'
                        text={isAddingOrder ? undefined : (edit ? 'ZATWIERDŹ EDYCJĘ' : (append ? 'WYSTAW DOMÓWIENIE' : 'ZŁÓŻ ZAMÓWIENIE'))}
                        onClick={step === 3 ? handleSubmit : orderState}
                        disabled={_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)}
                        backgroundColor='#070'
                        brandColoredButton={false}
                    >
                        {isAddingOrder ? (
                            <ReactSpinner animation='border' style={{ verticalAlign: 'middle' }} />
                        ) : undefined}
                    </ButtonComponent>
                </div>
            )}
        </div>
    );
};

export default NewOrderSummary;
