import ButtonComponent from 'components/ButtonComponent';
import { updatePostals } from 'lib/communication/postal';
import _ from 'lodash';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const Postals = () => {
    const [files, setFiles] = useState<File[]>();
    const [responseMessage, setResponseMessages] = useState<string>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleUploadFile = async () => {
        if (files) {
            setIsUploading(true);
            const response = await updatePostals(files);
            setIsUploading(false);
            setResponseMessages(response);
        }
    };

    const setNewFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFiles: File[] = [];

        _.map(e.target.files, (f) => {
            newFiles.push(new File([f], f.name));
        });

        setFiles(newFiles);
    };

    return (
        <div>
            <h2>Aktualizacja bazy kodów pocztowych</h2>
            <br />
            <input type='file' onChange={setNewFiles} accept='.txt, .csv' multiple />
            <br />
            <br />
            <div>
                { responseMessage }
            </div>
            <br />
            <ButtonComponent
                text='Aktualizuj'
                onClick={handleUploadFile}
                margin='0.25rem'
                onHoverAnimation={3}
                disabled={!files}
            />
            <div style={{ fontStyle: 'italic', marginRight: 'auto' }}>
                {' '}
                {isUploading ? <Spinner animation='border' /> : <></>}
                {' '}
            </div>
        </div>
    );
};

export default Postals;
