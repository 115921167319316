import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import './NewOrder.scss';
import { MAX_ORDER_DESC_LENGTH, MAX_ORDER_OWN_NUMBER } from 'lib/util';
import ButtonComponent from 'components/ButtonComponent';
import DeliveryAddressSelect from 'components/DeliveryAddressSelect';
import { UserDeliveryAddress } from 'lib/types';
import { AppState } from 'reducers';
import { useSelector } from 'react-redux';

interface NewOrderFormProps {
    completeAction: () => void;
    addresses: UserDeliveryAddress[];
    deliveryAddress: UserDeliveryAddress | undefined;
    setDeliveryAddress: (address: UserDeliveryAddress | undefined) => void;
    customerOrderNumber: string;
    setCustomerOrderNumber: (value: string) => void;
    orderNumber: string;
    orderDesc: string;
    setOrderDesc: (value: string) => void;
    clientDesc: string;
    setClientDesc: (value: string) => void;
    rooferLogin: string;
    setRooferLogin: (value: string) => void;
    rooferInfo: JSX.Element;
    rooferLoginCase: Number;
    addToExisting: boolean;
    setAddToExisting: (value: boolean) => void;
    verifyRoofer: () => Promise<boolean>;
    handleSubmit: (event: React.MouseEvent) => Promise<void>;
    message: string;
    nextPage: boolean;
    show: boolean;
    setShow: (value: boolean) => void;
}

const NewOrderForm = (props: NewOrderFormProps) => {
    const append = useSelector((state: AppState) => state.data.orders.append);
    const [errorToShow, setErrorToShow] = useState<string | null>(null);

    const closeConfirmWindow = () => {
        if (!props.nextPage) return;
        props.completeAction();
    };

    useEffect(() => {
        if (append) props.setAddToExisting(true);
    }, [append]);

    return (
        <div>
            <div className='NewOrder-desc'>
                PROSZĘ UZUPEŁNIĆ DANE DO REALIZACJI ZAMÓWIENIA
            </div>
            <div className='NewOrder-form'>
                <Form name='NewOrder-form' autoComplete='on'>
                    <Form.Group controlId='DeliveryAddress'>
                        <DeliveryAddressSelect
                            addresses={props.addresses}
                            deliveryAddress={props.deliveryAddress}
                            setDeliveryAddress={props.setDeliveryAddress}
                        />
                        <Form.Label className='NewOrder-desc-error'>&nbsp;</Form.Label>
                    </Form.Group>
                    <Form.Group controlId='OrderNumber'>
                        <Form.Control maxLength={MAX_ORDER_OWN_NUMBER} type='text' value={props.customerOrderNumber} onChange={(event: React.ChangeEvent<HTMLInputElement>): void => props.setCustomerOrderNumber(event.target.value)} placeholder='Wpisz numer własny zamówienia' />
                        <Form.Label
                            className='NewOrder-desc-charLimitLabel'
                            style={{ color: props.customerOrderNumber.length === MAX_ORDER_OWN_NUMBER ? 'red' : 'inherit' }}
                        >
                            {props.customerOrderNumber.length}
                            {' '}
                            /
                            {MAX_ORDER_OWN_NUMBER}
                        </Form.Label>
                    </Form.Group>
                    <Form.Group controlId='OrderDesc'>
                        <Form.Control maxLength={MAX_ORDER_DESC_LENGTH} as='textarea' name='OrderDesc' autoComplete='on' value={props.orderDesc} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => props.setOrderDesc(event.target.value)} rows={4} placeholder='Wpisz opis zamówienia' />
                        <Form.Label
                            className='NewOrder-desc-charLimitLabel'
                            style={{ color: props.orderDesc.length === MAX_ORDER_DESC_LENGTH ? 'red' : 'inherit' }}
                        >
                            {props.orderDesc.length}
                            {' '}
                            /
                            {MAX_ORDER_DESC_LENGTH}
                        </Form.Label>
                    </Form.Group>
                    <Form.Group controlId='ClientDesc'>
                        <Form.Control maxLength={MAX_ORDER_DESC_LENGTH} as='textarea' name='ClientDesc' autoComplete='on' value={props.clientDesc} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => props.setClientDesc(event.target.value)} rows={3} placeholder='Opis własny klienta' />
                        <Form.Label
                            className='NewOrder-desc-charLimitLabel'
                            style={{ color: props.clientDesc.length === MAX_ORDER_DESC_LENGTH ? 'red' : 'inherit' }}
                        >
                            {props.clientDesc.length}
                            {' '}
                            /
                            {MAX_ORDER_DESC_LENGTH}
                        </Form.Label>
                    </Form.Group>
                    <Form.Group controlId='RooferLogin'>
                        <Form.Control
                            type='text'
                            value={props.rooferLogin}
                            isValid={props.rooferLoginCase === 2}
                            isInvalid={props.rooferLoginCase === 1}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                props.setRooferLogin(event.target.value);
                            }}
                            onBlur={async () => await props.verifyRoofer()}
                            placeholder='Login dekarza'
                        />
                    </Form.Group>
                    <Form.Group controlId='RooferInfo'>
                        <Form.Label>{props.rooferInfo}</Form.Label>
                    </Form.Group>
                    <Form.Group controlId='Ordering'>
                        <Form.Check name='ordering' type='checkbox' label='Domówienie' disabled={append} checked={props.addToExisting} onChange={() => props.setAddToExisting(!props.addToExisting)} />
                    </Form.Group>
                </Form>
                <Modal show={props.show} onHide={() => { props.setShow(false); setErrorToShow(null); }} backdrop='static'>
                    <Modal.Header className='NewOrder-modal-header' />
                    <Modal.Body>{errorToShow ?? props.message}</Modal.Body>
                    <Modal.Footer className='NewOrder-modal-footer one-button'>
                        <ButtonComponent
                            text='ZAMKNIJ'
                            onClick={() => { props.setShow(false); setErrorToShow(null); closeConfirmWindow(); }}
                            marginTop='0px'
                            marginBottom='0px'
                            onHoverAnimation={3}
                        />
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default NewOrderForm;
