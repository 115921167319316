import React, { useEffect, useState } from 'react';
import './Cards.scss';
import { Product } from 'lib/types';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { buildResourceLink } from 'lib/communication';
import { AppState, useUser } from 'reducers';
import EditImageModalCard from './EditImageModalCard';
import { FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner';

interface PDescProps {
    selectedProduct: Product | null;
}

const ProductDescriptionDetails = (props: PDescProps) => {
    const user = useUser();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isProductDetailsFetching = useSelector((state: AppState) => state.data.orders.props.isProductDetailsFetching);

    useEffect(() => {
        if (props.selectedProduct) {
            const image = new Image();
            image.src = props.selectedProduct.image ? buildResourceLink(props.selectedProduct.image) : `${process.env.PUBLIC_URL}/images/ikona.jpg`;

            image.onload = () => {
                setWidth(image.width);
                setHeight(image.height);
            };
        }
    }, []);

    return (
        <Card style={{ width: '100%', height: '100%' }}>
            {isProductDetailsFetching || !props.selectedProduct ? (
                <Spinner />
            ) : (
                props.selectedProduct && (
                    <>
                        {user?.permissions.canManageAssortmentImages && (
                            <EditImageModalCard
                                path={props.selectedProduct.symKar}
                                setModalVisibility={setIsModalOpen}
                                isModalOpen={isModalOpen}
                                name={props.selectedProduct.description as string}
                            />
                        )}
                        <Card.Header style={{ padding: '0.25rem 1.25rem' }}>
                            <span className='NewOrder-selectedProduct'>{props.selectedProduct.description}</span>
                        </Card.Header>
                        <Card.Body className='Cards-ProductDescriptionDetails'>
                            <ListGroup className='list-group-flush'>
                                <ListGroupItem className='Cards-ProductDescriptionDetails-data'>
                                    <Card.Title>Dane produktu</Card.Title>
                                    <div className='Cards-ProductDescriptionDetails-data-row'>
                                        <b>JM: </b>
                                        {props.selectedProduct.priceInfo?.units?.map((u) => u.description).join(', ') || 'Brak danych'}
                                    </div>
                                    <div className='Cards-ProductDescriptionDetails-data-row'>
                                        <b>Cena przed rabatem: </b>
                                        {props.selectedProduct.priceInfo?.price ?? 'Brak danych'}
                                    </div>
                                    <div className='Cards-ProductDescriptionDetails-data-row'>
                                        <b>Rabat: </b>
                                        {props.selectedProduct.priceInfo?.discount ?? 0} %
                                    </div>
                                    <div className='Cards-ProductDescriptionDetails-data-row'>
                                        <b>Cena po rabacie: </b>
                                        {props.selectedProduct.priceInfo?.price ?
                                            ((props.selectedProduct.priceInfo.price * (1 - (props.selectedProduct.priceInfo.discount ?? 0) / 100)).toFixed(2)) :
                                            'Brak danych'}
                                    </div>
                                    <div className='Cards-ProductDescriptionDetails-data-row'>
                                        <b>Stawka: </b>
                                        {props.selectedProduct.priceInfo?.vatRate ?? 'Brak danych'} %
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                            <div
                                className={user?.permissions.canManageAssortmentImages ? 'Cards-ProductDescriptionDetails-photo-admin' : 'Cards-ProductDescriptionDetails-photo'}
                                style={{ minWidth: width === height && width <= 180 ? '180px' : '' }}
                            >
                                <img
                                    style={{ minWidth: width === height && width <= 180 ? '180px' : '' }}
                                    src={props.selectedProduct.image ? buildResourceLink(props.selectedProduct.image) : `${process.env.PUBLIC_URL}/images/ikona.jpg`}
                                    alt=''
                                    height='auto'
                                />
                                {user?.permissions.canManageAssortmentImages && (
                                    <div className='NewOrder-edit-image-container'>
                                        <p className='NewOrder-image-name'>Nazwa pliku: {props.selectedProduct.symKar}</p>
                                        <div className='edit-button'>
                                            <Button
                                                variant='outline'
                                                title='Edytuj'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setIsModalOpen(true);
                                                }}
                                                tabIndex={-1}
                                            >
                                                <FaEdit />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </>
                )
            )}
        </Card>
    );
};

export default ProductDescriptionDetails;
