import { isSafari } from 'lib/util';
import React from 'react';
import { Form } from 'react-bootstrap';

interface DatePickerProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    id: string;
    type: string;
    isInvalid?: boolean;
    min?: number | string;
    className?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const cloneAndModifyEvent = (
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: string
): React.ChangeEvent<HTMLInputElement> => ({
    ...e,
    target: {
        ...e.target,
        value: newValue
    }
});

const DatePicker = (props: DatePickerProps) => {
    const dateTimeLocalToDateTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.type === 'datetime-local') props.onChange(cloneAndModifyEvent(event, event.target.value.replace('T', ' ')));
        else props.onChange(event);
    };
    return (
        <>
            <Form.Control
                type={props.type}
                id={props.id}
                placeholder='YYYY-MM-DD'
                pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                value={props.value}
                onChange={dateTimeLocalToDateTime}
                isInvalid={props.isInvalid}
                min={props.min}
                className={props.className}
                onBlur={props.onBlur}
            />
            {isSafari() && (
                <Form.Control.Feedback type='invalid' tooltip>
                    Użyj formatu YYYY-MM-DD
                </Form.Control.Feedback>
            )}
        </>
    );
};

export default DatePicker;
