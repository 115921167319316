import React, { useEffect, useState } from 'react';
import { AppState } from 'reducers';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import { getLatestSchemaValidationResults, getSchemaValidationResults, getTestsResults } from 'lib/communication/webserviceTests';
import './TestsResults.scss';
import _, { size } from 'lodash';
import { Collapse } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { NUMBER_OF_LATEST_SCHEMA_VALIDATION_RESULTS, NUMBER_OF_TESTS_RESULTS_ON_DESKTOP, NUMBER_OF_TESTS_RESULTS_ON_MOBILE } from 'lib/types';
import AutomaticTestsResults from './AutomaticTestsResults/AutomaticTestsResults';
import SchemaValidationResults from './SchemaValidationResults/SchemaValidationResults';
import LatestSchemaValidationResults from './LatestSchemaValidationResults/LatestSchemaValidationResults';

const TestsResults = () => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const errorsOccured = useSelector((state: AppState) => state.data.schemaValidationResults.errorsOccured);
    const latestSchemaValidationErrorsOccured = useSelector((state: AppState) => state.data.schemaValidationResults.latestResultsErrorsOccured);
    const testResults = useSelector((state: AppState) => state.data.webserviceTestResults.results);
    const webserwisTestsResultsIsFetching = useSelector((state: AppState) => state.data.webserviceTestResults.props.isFetching);
    const schemaValidationResultsIsFetching = useSelector((state: AppState) => state.data.schemaValidationResults.props.isFetching);
    const latestSchemaValidationResultsIsFetching = useSelector((state: AppState) => state.data.schemaValidationResults.props.isFetchingLatestResults);

    const [automaticTestsOpen, setAutomaticTestsOpen] = useState(false);
    const [schemaValidationResultsOpen, setSchemaValidationResultsOpen] = useState(false);
    const [latestSchemaValidationResultsOpen, setLatestSchemaValidationResultsOpen] = useState(false);

    useEffect(() => {
        getTestsResults();
    }, []);

    useEffect(() => {
        getSchemaValidationResults(isDesktop ? NUMBER_OF_TESTS_RESULTS_ON_DESKTOP : NUMBER_OF_TESTS_RESULTS_ON_MOBILE);
        getLatestSchemaValidationResults(NUMBER_OF_LATEST_SCHEMA_VALIDATION_RESULTS);
    }, [isDesktop]);

    if (webserwisTestsResultsIsFetching || schemaValidationResultsIsFetching || latestSchemaValidationResultsIsFetching) return <Spinner />;
    return (
        <>
            <div
                className={`TestsResult-CollapseHeader d-flex align-items-center`}
                onClick={() => setAutomaticTestsOpen(!automaticTestsOpen)}
            >
                <p className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>Automatyczne testy</p>
                <p className={isDesktop ? 'desktop-font-size' : 'mobile-font-size'}>
                    Ostatnie wykonanie: {testResults[0]?.date ?? 'brak'}{' '}
                    {testResults[0] &&
                        (size(_.filter(testResults[0].testCasesResults, ({ status }) => status !== '0')) === 0 ?
                            '(pomyślny)' :
                            '(wystąpiły błędy)')}
                </p>
                <b>{automaticTestsOpen ? <FaAngleUp size='40px' /> : <FaAngleDown size='40px' />}</b>
            </div>
            <Collapse in={automaticTestsOpen}>
                <div id='collapse-result'>
                    {automaticTestsOpen && <AutomaticTestsResults />}
                </div>
            </Collapse>
            <br />
            <div
                className={`TestsResult-CollapseHeader d-flex align-items-center`}
                onClick={() => setLatestSchemaValidationResultsOpen(!latestSchemaValidationResultsOpen)}
            >
                <p className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>Ostatnie błędy walidacji</p>
                {<p className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>{errorsOccured ? 'Wystąpiły błędy' : 'Brak błędów'}</p>}
                <b>{latestSchemaValidationResultsOpen ? <FaAngleUp size='40px' /> : <FaAngleDown size='40px' />}</b>
            </div>
            <Collapse in={latestSchemaValidationResultsOpen}>
                <div id='collapse-result'>
                    {latestSchemaValidationResultsOpen && <LatestSchemaValidationResults />}
                </div>
            </Collapse>
            <br />
            <div
                className={`TestsResult-CollapseHeader d-flex align-items-center`}
                onClick={() => setSchemaValidationResultsOpen(!schemaValidationResultsOpen)}
            >
                <p className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>Walidacja schematów odpowiedzi</p>
                {<p className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>{latestSchemaValidationErrorsOccured ? 'Wystąpiły błędy' : 'Brak błędów'}</p>}
                <b>{schemaValidationResultsOpen ? <FaAngleUp size='40px' /> : <FaAngleDown size='40px' />}</b>
            </div>
            <Collapse in={schemaValidationResultsOpen}>
                <div id='collapse-result'>
                    {schemaValidationResultsOpen && <SchemaValidationResults />}
                </div>
            </Collapse>
        </>
    );
};

export default TestsResults;
