import { API_ADDRESS, post, del, get } from 'lib/communication';
import store from 'store';
import { dataActions } from 'reducers';
import produce from 'immer';
import { Order, DetailedOrder, Product, ProductPrice, LocalCart, ProductsGroup, AddOrderRequestDTO, ImportedProduct, ProductsType, GutteringSystemData, ProductInLocalCart, AdditionalProduct, GutterProduct, FoundProduct, OrderPartStatus, OrderStatusNameToLabel, OrderStatusName, EditAssortmentImage, AddOrderResponse, UserDeliveryAddress, ProductInGroup, AppendOrderRequestDTO } from 'lib/types';
import _ from 'lodash';
import { CartDTO, GutterProductDTO, IsodProductDTO, ProductGroupsDTO, ProductInCartDTO, Response } from './communicationTypes';
import { getCurrentTimeStamp, isSafari } from 'lib/util';
import { userService } from 'services';
import { getUnitOrDefault } from 'lib/utilityMetods';
import { createOrder } from 'lib/gtm-utils';

const setIsImageEdit = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsEditImageUploadFetching(isFetching));
};

const setAreImagesEdit = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsEditImagesUploadFetching(isFetching));
};

const setAreGraphicsDownloaded = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsAssortmentGraphicsFetching(isFetching));
};

const setIsProductDetailsFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsProductDetailsFetching(isFetching));
};

const setIsAdditionalAssortmentDetailsFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setAdditionalAssortmentDetailsFetching(isFetching));
};

const setOrdersFetching = async (isFetching: object) => {
    const state = store.getState();
    store.dispatch(
        dataActions.setOrders(
            produce(state.data.orders, (draft) => {
                draft.props = {
                    ...draft.props,
                    ...isFetching
                };
            })
        )
    );
};

const toggleProductsOfGroupsFetching = async (path: string, set: boolean) => {
    const { productsOfGroupsFetching } = store.getState().data.orders.props;
    const newProds = [...productsOfGroupsFetching];

    if (set && productsOfGroupsFetching.indexOf(path) === -1) {
        newProds.push(path);
    }

    if (!set && productsOfGroupsFetching.indexOf(path) > -1) {
        newProds.splice(newProds.indexOf(path), 1);
    }

    store.dispatch(dataActions.setProductsOfGroupsFetching(newProds));
};

export const getGroups = async (): Promise<boolean> => {
    const route: string = 'products/getGroups';
    setOrdersFetching({ isFetchingGroups: true });
    try {
        const { company, mag } = userService.getUser();
        const data = await get<ProductsGroup[]>(`${API_ADDRESS}${route}?company=${company}&mag=${mag}`);
        if (data.ok && data.parsedBody) {
            const groups = data.parsedBody;
            store.dispatch(dataActions.setGroups(groups));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetchingGroups: false });
    }
};

export const getProductsByGroup = async (group: string, company: string | undefined): Promise<boolean> => {
    const route: string = 'products/getProducts';
    setOrdersFetching({ isFetchingGroups: true });
    toggleProductsOfGroupsFetching(group, true);
    try {
        const { login, logo } = userService.getUser();
        const data = await post<ProductInGroup[]>(`${API_ADDRESS}${route}`, {
            company,
            group,
            login,
            logo
        });
        if (data.ok && data.parsedBody) {
            const fetchedProducts = data.parsedBody;
            store.dispatch(dataActions.addProductsInGroup(fetchedProducts));
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetchingGroups: false });
        toggleProductsOfGroupsFetching(group, false);
    }
};

export const getProductWithPrice = async (products: { symKar: string; unit: string }[], isAdditionalAssortmentInfoFetching: boolean = false): Promise<boolean> => {
    const route: string = 'products/getProductWithPrice';
    if (!isAdditionalAssortmentInfoFetching) setIsProductDetailsFetching(true);
    else setIsAdditionalAssortmentDetailsFetching(true);
    try {
        const { login, logo } = userService.getUser();
        const data = await post<Product[]>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            products
        });
        if (data.ok && data.parsedBody) {
            store.dispatch(dataActions.addProducts(data.parsedBody));
            return true;
        } return false;
    } catch (err) {
        console.log('Error', err);
        return false;
    } finally {
        if (!isAdditionalAssortmentInfoFetching) setIsProductDetailsFetching(false);
        else setIsAdditionalAssortmentDetailsFetching(false);
    }
};

export const searchProducts = async (description: string): Promise<FoundProduct[] | false> => {
    const route: string = 'products/searchProducts';
    const searchPhrase: string = description.replace(/\s/g, '%');
    setOrdersFetching({ isSearchingProducts: true });
    try {
        const { login, logo, company, mag } = userService.getUser();
        const { lang } = store.getState().conf;
        const data = await post<FoundProduct[]>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            company,
            mag,
            lang,
            searchPhrase
        });
        if (data.ok && data.parsedBody) {
            const fetchedProducts = data.parsedBody;
            return fetchedProducts;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isSearchingProducts: false });
    }
};

export const getProductsInfo = async (products: { symKar: string, unit: string }[], keepInStore: boolean): Promise<Product[] | false> => {
    const route: string = 'products/getImportedProductsInfo';
    setOrdersFetching({ isFetchingProductInfo: true });
    try {
        const { login, logo } = userService.getUser();
        const data = await post<Product[]>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            products
        });
        if (data.ok && data.parsedBody) {
            const fetchedProducts = data.parsedBody;
            if (keepInStore) {
                store.dispatch(dataActions.addProducts(fetchedProducts));
            }
            return fetchedProducts;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetchingProductInfo: false });
    }
};

export const getOrdersList = async (
    status: OrderStatusName,
    orderNumber: string,
    customerOrderNumber: string,
    dateFrom: string,
    dateTo: string,
    description: string
): Promise<boolean> => {
    const route: string = 'orders';
    setOrdersFetching({ isFetchingOrders: true });
    try {
        const { logo, company } = userService.getUser();
        const data = await post<Order[]>(`${API_ADDRESS}${route}`, {
            logo,
            company,
            status: OrderStatusNameToLabel[status],
            orderNumber,
            customerOrderNumber,
            dateFrom,
            dateTo,
            description
        });
        if (data.ok && data.parsedBody) {
            store.dispatch(dataActions.setOrdersList({ status, data: data.parsedBody }));
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetchingOrders: false });
    }
};

export const addOrder = async (detailedOrder: DetailedOrder, summaryPrice: number): Promise<boolean> => {
    const route: string = 'orders/createNewOrder';
    setOrdersFetching({ isAddingOrder: true });
    try {
        const { login, logo } = userService.getUser();
        const addOrderRequestDTO: AddOrderRequestDTO = {
            login,
            logo,
            description: detailedOrder.description,
            customerDesc: detailedOrder.customerDesc,
            customerOrderNumber: detailedOrder.customerOrderNumber,
            address: detailedOrder.address?.id as string || '0',
            roofer: detailedOrder.rooferLogin,
            addToExisting: detailedOrder.addToExisting,
            products: detailedOrder.products.map((product) => ({
                symKar: product.product.symKar,
                quantity: product.quantity,
                unit: product.product.unit,
                dimension: product.dimension ?? undefined,
                additionsSelected: product.additionsSelected !== null ?
                    product.additionsSelected.map((isSelected, index) => {
                        if (isSelected) {
                            return product.product.additions[index];
                        }
                        return null;
                    }) : [null]
            }))
        };

        const data = await post<AddOrderResponse>(`${API_ADDRESS}${route}`, addOrderRequestDTO);
        if (data.ok && data.parsedBody) {
            if ('error' in data.parsedBody) return false;
            createOrder(data.parsedBody.NagId, detailedOrder.products, summaryPrice);
            store.dispatch(dataActions.clearLocalCart());
            store.dispatch(dataActions.clearCart());
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isAddingOrder: false });
    }
};

export const appendOrder = async (detailedOrder: DetailedOrder, nagIdSource: string, summaryPrice: number): Promise<boolean> => {
    const route: string = 'orders/createNewOrder';
    setOrdersFetching({ isAddingOrder: true });
    try {
        const { login, logo } = userService.getUser();
        const appendOrderRequestDTO: AppendOrderRequestDTO = {
            login,
            logo,
            description: detailedOrder.description,
            customerDesc: detailedOrder.customerDesc,
            customerOrderNumber: detailedOrder.customerOrderNumber,
            address: detailedOrder.address?.id as string || '0',
            roofer: detailedOrder.rooferLogin,
            addToExisting: detailedOrder.addToExisting,
            products: detailedOrder.products.map((product) => ({
                symKar: product.product.symKar,
                quantity: product.quantity,
                unit: product.product.unit,
                dimension: product.dimension ?? undefined,
                additionsSelected: product.additionsSelected !== null ?
                    product.additionsSelected.map((isSelected, index) => {
                        if (isSelected) {
                            return product.product.additions[index];
                        }
                        return null;
                    }) : [null]
            })),
            nagIdSource
        };

        const data = await post<AddOrderResponse>(`${API_ADDRESS}${route}`, appendOrderRequestDTO);
        if (data.ok && data.parsedBody) {
            createOrder(data.parsedBody.NagId, detailedOrder.products, summaryPrice);
            store.dispatch(dataActions.clearLocalCart());
            store.dispatch(dataActions.clearCart());
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isAddingOrder: false });
    }
};

export const deleteOrder = async (orderNumber: string): Promise<boolean> => {
    const route: string = 'orders/delete';
    setOrdersFetching({ pdfIsFetching: true });
    try {
        const { logo } = userService.getUser();
        const data = await del<Response>(`${API_ADDRESS}${route}`, {
            orderId: orderNumber,
            logo
        });
        if (data.ok) {
            const state = store.getState();
            _.forEach(state.data.orders.orders, (orders, key) => {
                if (orders !== null) {
                    const filtered: Order[] = _.filter(orders, (o) => o.id !== orderNumber);
                    if (orders.length !== filtered.length) {
                        store.dispatch(dataActions.setOrdersList({ status: key as OrderStatusName, data: filtered }));
                    }
                }
            });
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ pdfIsFetching: false });
    }
};

export const modifyOrder = async (detailedOrder: DetailedOrder): Promise<boolean> => {
    const route: string = 'orders/modify';
    setOrdersFetching({ isAddingOrder: true });
    try {
        const { login, logo } = userService.getUser();

        const addOrderRequestDTO: AddOrderRequestDTO = {
            login,
            logo,
            orderId: detailedOrder.orderId,
            customerOrderNumber: detailedOrder.customerOrderNumber,
            description: detailedOrder.description,
            customerDesc: detailedOrder.customerDesc,
            address: detailedOrder.address?.id as string || '0',
            roofer: detailedOrder.rooferLogin,
            addToExisting: detailedOrder.addToExisting,
            products: detailedOrder.products.map((product) => ({
                symKar: product.product.symKar,
                quantity: product.quantity,
                unit: product.product.unit,
                dimension: product.dimension ?? undefined,
                additionsSelected: product.additionsSelected !== null ?
                    product.additionsSelected.map((isSelected, index) => {
                        if (isSelected) {
                            return product.product.additions[index];
                        }
                        return null;
                    }) : [null]
            }))
        };
        const data = await post<Response>(`${API_ADDRESS}${route}`, addOrderRequestDTO);
        if (data.ok) {
            store.dispatch(
                dataActions.clearLocalEditCart()
            );
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isAddingOrder: false });
    }
};

export const getDetailedOrder = async (orderId: string): Promise<boolean> => {
    const { login, logo } = userService.getUser();
    const route: string = 'orders/getOrder';
    setOrdersFetching({ isFetching: true });
    try {
        const data = await post<DetailedOrder>(`${API_ADDRESS}${route}`, {
            logo,
            login,
            orderId
        });
        if (data.ok && data.parsedBody && !('error' in data.parsedBody)) {
            const order = data.parsedBody;

            const detailedOrder: DetailedOrder = {
                orderId: order.orderId,
                orderNumber: order.orderNumber,
                customerOrderNumber: order.customerOrderNumber,
                description: order.description,
                customerDesc: order.customerDesc,
                status: order.status,
                address: new UserDeliveryAddress(order.address),
                addToExisting: order.addToExisting,
                products: order.products,
                rooferLogin: order.rooferLogin
            };

            store.dispatch(dataActions.setDetailedOrder(detailedOrder));

            _.map(detailedOrder.products, (productInOrder) => {
                store.dispatch(dataActions.addToLocalEditCart({
                    groupPath: productInOrder.product.description,
                    product: {
                        dimension: productInOrder.dimension,
                        quantity: productInOrder.quantity,
                        additionsSelected: productInOrder.additionsSelected,
                        product: productInOrder.product,
                        mainProductSymkar: null,
                        unit: productInOrder.product.priceInfo.units.find((u) => u.unit === productInOrder.unit)!
                    },
                    productsType: 'mainProducts'
                }));
            });

            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetching: false });
    }
};

export const getOrderById = async (orderId: string, replaceLocalCart: boolean = false): Promise<DetailedOrder | false> => {
    const { login, logo } = userService.getUser();
    const route: string = 'orders/getOrder';
    setOrdersFetching({ isFetching: true });

    try {
        const data = await post<DetailedOrder>(`${API_ADDRESS}${route}`, {
            logo,
            login,
            orderId
        });
        if (data.ok && data.parsedBody) {
            const order = data.parsedBody;

            const detailedOrder: DetailedOrder = {
                orderId: order.orderId,
                orderNumber: order.orderNumber,
                customerOrderNumber: order.customerOrderNumber,
                description: order.description,
                customerDesc: order.customerDesc,
                status: order.status,
                address: new UserDeliveryAddress(order.address),
                addToExisting: order.addToExisting,
                products: order.products,
                rooferLogin: order.rooferLogin
            };

            if (replaceLocalCart) {
                const data2 = await post<{ products: Product[], prouctsNotAvailable: Product[] }>(`${API_ADDRESS}products/areProductsAvailable`, {
                    logo,
                    login,
                    products: _.map(detailedOrder.products, (productInOrder) => productInOrder.product)
                });

                if (data2.ok && data2.parsedBody) {
                    if (data2.parsedBody!.prouctsNotAvailable.length > 0) {
                        store.dispatch(dataActions.setCartProdNotAvailable(true));
                    }

                    store.dispatch(dataActions.clearLocalCart());

                    _.map(detailedOrder.products, (productInOrder) => {
                        if (data2.parsedBody!.products.findIndex((prd) => prd.symKar === productInOrder.product.symKar) > -1) {
                            store.dispatch(dataActions.addToLocalCart({
                                groupPath: productInOrder.product.description,
                                product: {
                                    dimension: productInOrder.dimension,
                                    quantity: productInOrder.quantity,
                                    additionsSelected: productInOrder.additionsSelected,
                                    product: productInOrder.product,
                                    mainProductSymkar: null,
                                    unit: productInOrder.product.priceInfo.units.find((u) => u.unit === productInOrder.unit)!
                                },
                                productsType: 'mainProducts'
                            }));
                        }
                    });
                } else return false;
            }
            return detailedOrder;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetching: false });
    }
};

export const getOrderPdf = async (orderId: string, orderNet: number, orderVat: number, orderGross: number): Promise<boolean> => {
    const route: string = 'orders/pdf';
    const { login, logo, company, wydr } = userService.getUser();
    let windowReference: Window | null = null;
    if (isSafari()) {
        windowReference = window.open();
    }

    setOrdersFetching({ pdfIsFetching: true });
    try {
        const res = await post<any>(`${API_ADDRESS}${route}`, {
            logo,
            login,
            company,
            orderId,
            wydr,
            orderNet,
            orderVat,
            orderGross
        });
        if (res.ok) {
            const blob = await res.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            if (isSafari()) {
                if (windowReference !== null) windowReference.location.href = url;
            } else {
                window.open(url);
            }
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ pdfIsFetching: false });
    }
};

export const getProductPriceInfo = async (product: Product, index?: number): Promise<boolean> => {
    const route: string = 'products/getProductPrice';
    try {
        const { login, logo } = userService.getUser();
        const data = await post<ProductPrice>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            unit: product.unit,
            symKar: product.symKar
        });
        if (data.ok && data.parsedBody) {
            if (index !== undefined) {
                store.dispatch(
                    dataActions.setAdditionalAssortmentPrice({ productPrice: data.parsedBody, index })
                );
            }
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetching: false });
    }
};

export const getAdditionalAssortmentInfo = async (products: AdditionalProduct[]): Promise<boolean> => {
    const route: string = 'products/getImportedProductsInfo';
    try {
        const { login, logo } = userService.getUser();
        const data = await post<Product[]>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            products
        });
        if (data.ok && data.parsedBody) {
            const importedProducts = data.parsedBody;
            store.dispatch(dataActions.addProducts(importedProducts));
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetching: false });
    }
};

export const getImportedProductsInfo = async (products: ImportedProduct[], reducer: keyof typeof dataActions, systemData?: GutteringSystemData): Promise<boolean> => {
    const route: string = 'products/getImportedProductsInfo';
    setOrdersFetching({ isFetching: true });
    try {
        const { login, logo } = userService.getUser();
        const data = await post<Product[]>(`${API_ADDRESS}${route}`, {
            login,
            logo,
            products
        });
        if (data.ok && data.parsedBody) {
            const parsedProducts = _.map(products, (product) => {
                if (!systemData) {
                    const foundPrd = _.find(data.parsedBody, (p) => p.symKar === product.symKar);
                    return {
                        product: foundPrd,
                        quantity: Math.ceil(product.quantity),
                        dimension: (foundPrd && !foundPrd.dimensionCanEdit) ? foundPrd.dimensionDefault : (product.dimension && product.dimension > 0) ? product.dimension : foundPrd?.isDimension ? foundPrd.dimensionDefault : 1,
                        additionsSelected: product.additionsSelected
                    };
                }
                return {
                    product: _.find(data.parsedBody, (p) => p.symKar === product.symKar)!,
                    quantity: product.quantity,
                    dimension: product.dimension,
                    additionsSelected: product.additionsSelected,
                    systemData
                };
            });
            store.dispatch(
                dataActions[reducer](parsedProducts)
            );
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetching: false });
    }
};

export const getImportedProductsInfoMocked = async (products: ImportedProduct[], reducer: keyof typeof dataActions): Promise<boolean> => {
    setOrdersFetching({ isFetching: true });
    try {
        const prod = products[0];
        const pathValue = [`plyty/${prod.symKar}`];
        const responseMocked = {
            symKar: prod.symKar,
            path: pathValue,
            description: 'plyty wartswowe',
            descriptionEN: 'plyty warstwowe',
            descriptionRU: 'plyty warstwowe',
            unit: String(prod.dimension),
            isDimension: true,
            dimensionDefault: prod.quantity,
            remarks: '',
            additions: [],
            priceInfo: {
                price: 100,
                priceWithFoil: 11,
                discount: 1,
                discountWithFoil: 2,
                priceAfterDiscount: 98,
                priceWithFoilAfterDiscount: 96,
                vatRate: 23,
                vat: 22,
                vatWithFoil: 24,
                gross: 120,
                grossWithFoil: 122,
                converterM2: 1.3
            },
            additionalAssortment: null,
            additionsSelected: [false]
        };
        const data = { parsedBody: responseMocked, ok: true };
        if (data.ok && data.parsedBody) {
            _.map(products, (product) => {
                store.dispatch(
                    dataActions[reducer]({
                        groupPath: `plyty`,
                        productsType: 'mainProducts' as ProductsType,
                        product: {
                            product: data.parsedBody,
                            quantity: product.quantity,
                            dimension: product.dimension,
                            additionsSelected: null
                        }
                    })
                );
            });
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setOrdersFetching({ isFetching: false });
    }
};

export const setSessionCart = async (localCart: LocalCart, summaryPrice: number): Promise<boolean> => {
    const route: string = 'cart/setCart';
    const mapProduct = (item: ProductInLocalCart) => ({
        quantity: item.quantity,
        dimension: item.dimension,
        additionsSelected: item.additionsSelected,
        symKar: item.product.symKar,
        unit: getUnitOrDefault(item).unit,
        mainProductSymkar: item.mainProductSymkar
    } as ProductInCartDTO);
    try {
        const cart: CartDTO = {
            productGroups: localCart.productGroups.map((group) => ({
                groupPath: group.groupPath,
                mainProducts: group.mainProducts.map(mapProduct),
                additionalProducts: group.additionalProducts.map(mapProduct)
            } as ProductGroupsDTO)),
            gutterings: localCart.gutterings.map((item) => ({
                quantity: item.quantity,
                systemData: item.systemData,
                symKar: item.product.symKar,
                unit: item.product.unit
            } as GutterProductDTO)),
            isod: localCart.isod.map((group) => group.map((item) => ({
                additionsSelected: item.additionsSelected,
                quantity: item.quantity,
                dimension: item.dimension,
                symKar: item.product.symKar,
                unit: item.product.unit
            } as IsodProductDTO))),
            totalGrossValue: summaryPrice as number
        };
        const { id, login } = userService.getUser();
        const data = await post<Response>(`${API_ADDRESS}${route}`, {
            userId: id,
            login,
            localCart: JSON.stringify(cart)
        });
        if (data.ok && data.parsedBody) {
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    }
};

export const loadSessionCart = async (): Promise<boolean> => {
    const route: string = 'cart/loadCart';
    const gutterVersion = store.getState().conf.GutterCreatorVersion;
    try {
        const { id, login, logo } = userService.getUser();
        const data = await post<{ cart: CartDTO, products: { [id: string]: Product }, cartProdNotAvailable: boolean }>(`${API_ADDRESS}${route}`, {
            userId: id,
            login,
            logo
        });
        if (data.ok && data.parsedBody) {
            const parsedData = data.parsedBody;
            const mapProduct = (item: ProductInCartDTO) => ({
                additionsSelected: item.additionsSelected,
                quantity: item.quantity,
                dimension: item.dimension,
                product: parsedData.products[item.symKar],
                mainProductSymkar: item.mainProductSymkar,
                mainProduct: item.mainProductSymkar ? parsedData.products[item.mainProductSymkar] : null,
                unit: parsedData.products[item.symKar].priceInfo.units.find((u) => u.unit === item.unit)!
            });
            const cart: LocalCart = {
                productGroups: parsedData.cart.productGroups.map((group: ProductGroupsDTO) => ({
                    groupPath: group.groupPath,
                    mainProducts: group.mainProducts.map(mapProduct),
                    additionalProducts: group.additionalProducts.map(mapProduct)
                })),
                gutterings: gutterVersion === '2' ? (_.filter(parsedData.cart.gutterings.map((item: GutterProductDTO) => {
                    if (Object.keys(item.systemData as {}).includes('gutterShape')) {
                        return {
                            quantity: item.quantity,
                            systemData: item.systemData,
                            product: parsedData.products[item.symKar]
                        };
                    }
                    return undefined;
                }), (item) => item !== undefined) as GutterProduct[]) : (_.filter(parsedData.cart.gutterings.map((item: GutterProductDTO) => {
                    if (Object.keys(item.systemData as {}).includes('systemN')) {
                        return {
                            quantity: item.quantity,
                            systemData: item.systemData,
                            product: parsedData.products[item.symKar]
                        };
                    }
                    return undefined;
                }), (item) => item !== undefined) as GutterProduct[]),
                isod: parsedData.cart.isod.map((group: IsodProductDTO[]) => group.map((item: IsodProductDTO) => ({
                    additionsSelected: item.additionsSelected,
                    quantity: item.quantity,
                    dimension: item.dimension,
                    product: parsedData.products[item.symKar]
                })))
            };
            store.dispatch(dataActions.setPreviousSummaryPrice(parsedData.cart.totalGrossValue));
            store.dispatch(dataActions.setCartProdNotAvailable(parsedData.cartProdNotAvailable));
            store.dispatch(
                dataActions.setLocalCart(cart)
            );
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    }
};

export const resetGutteringsCart = async (edit: boolean, systemData: GutteringSystemData): Promise<boolean> => {
    if (edit) {
        store.dispatch(dataActions.clearLocalEditGutteringsCart(systemData));
    } else {
        store.dispatch(dataActions.clearLocalGutteringsCart(systemData));
    }
    return true;
};

const setIsOrderStatusFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsOrderStatusFetching(isFetching));
};

export const getOrderStatus = async (orderId: string) => {
    const route: string = 'orders/status';
    const prevOrderStatus = store.getState().data.orders.orderStatus;
    setIsOrderStatusFetching(true);
    try {
        const { company, logo } = userService.getUser();
        const data = await post<OrderPartStatus[]>(`${API_ADDRESS}${route}`, {
            company,
            logo,
            orderId
        });
        if (data.ok && data.parsedBody) {
            store.dispatch(dataActions.setOrderStatus({
                ...prevOrderStatus,
                parts: data.parsedBody
            }));
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setIsOrderStatusFetching(false);
    }
};

export const resetIsodCart = () => {
    store.dispatch(dataActions.clearIsodCart());
};

export const editImage = async (file: File): Promise<EditAssortmentImage | boolean> => {
    const route: string = 'products/editProductOrGroupImage';
    try {
        setIsImageEdit(true);
        const formData = new FormData();
        formData.append('file', file);
        const args: RequestInit = { method: 'post', body: formData };
        const data = await post<EditAssortmentImage>(`${API_ADDRESS}${route}`, formData, args);
        if (data.ok && data.parsedBody) return data.parsedBody;
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setIsImageEdit(false);
    }
};

export const editImages = async (file: File): Promise<EditAssortmentImage[] | boolean> => {
    const route: string = 'products/editProductOrGroupImages';
    try {
        setAreImagesEdit(true);
        const formData = new FormData();
        formData.append('file', file);
        const args: RequestInit = { method: 'post', body: formData };
        const data = await post<EditAssortmentImage[]>(`${API_ADDRESS}${route}`, formData, args);
        if (data.ok && data.parsedBody) return data.parsedBody;
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setAreImagesEdit(false);
    }
};

export const downloadAssortmentGraphics = async (): Promise<boolean> => {
    const route: string = 'products/downloadAssortmentGraphics';
    try {
        setAreGraphicsDownloaded(true);
        const response = await get<any>(`${API_ADDRESS}${route}`);
        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
            const a = document.createElement('a');
            document.body.append(a);
            a.href = url;
            a.download = `${getCurrentTimeStamp()}_zamówienia_grafiki.zip`;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            return true;
        } return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setAreGraphicsDownloaded(false);
    }
};
