import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Form, Collapse, Spinner } from 'react-bootstrap';
import { AppState, dataActions } from 'reducers';
import { FaAngleDown, FaAngleUp, FaTimes } from 'react-icons/fa';
import store from 'store';

import { AdditionalProduct, Product } from 'lib/types';
import { LG } from 'lib/util';
import './AdditionalAssortment.scss';
import NumberInput from 'components/NumberInput';
import ButtonComponent from 'components/ButtonComponent';

import { useMediaQuery } from 'react-responsive';
import { getDefaultUnitOfProduct, mapLocalCartToCartTableContent } from 'lib/utilityMetods';
import { useNotification } from 'components/NotificationComponent/NotificationComponent';
import { getProductWithPrice } from 'lib/communication/orders';
import useCartMessage from 'lib/hooks/useCartMessage';

interface AdditionalAssortmentProps {
    selectedProduct: Product,
}

interface AdditionalProductsGroup {
    description: string;
    icon: string;
    products: AdditionalProduct[];
    indexes: number[];
    shortNames: string[];
}

const AdditionalAssortment = ({ selectedProduct }: AdditionalAssortmentProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const { setShowCartMessage } = useCartMessage();

    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const productList = useSelector((state: AppState) => state.data.orders.groupsAndProducts.products);
    const localCart = useSelector((state: AppState) => (edit ? state.data.orders.localEditCart : state.data.orders.localCart));
    const showNotification = useNotification();
    const isAdditionalAssortmentFetching = useSelector((state: AppState) => state.data.orders.props.isAdditionalAssortmentInfoFetching);
    const showCartMessage = useSelector((state: AppState) => state.data.orders.showCartMessage);

    const [additionalAssortmentShow, setAdditionalAssortmentShow] = useState(false);
    const [additionalAssortmentQuantity, setAdditionalAssortmentQuantity] = useState(
        Array<number>(selectedProduct.additionalAssortment?.length ?? 0).fill(0)
    );

    const additionalAssortmentRef = useRef<null | HTMLDivElement>(null);

    const additionalAssortmentInCart = useMemo(() => _.map(_.find(mapLocalCartToCartTableContent(localCart).additionalItems, (item, key) => key === selectedProduct.symKar), (grp) => grp.products).flat(), [selectedProduct, localCart]);

    const [productAddedToCart, setProductAddedToCart] = useState<boolean>(false);

    useEffect(() => {
        const quantities = _.map(selectedProduct.additionalAssortment, (prd) => _.find(additionalAssortmentInCart, (prdInCart) => prdInCart.product.symKar === prd.symKar)?.quantity ?? 0);
        setAdditionalAssortmentQuantity(quantities);
    }, [additionalAssortmentInCart, selectedProduct.additionalAssortment]);

    useEffect(() => {
        setAdditionalAssortmentShow(false);
    }, [selectedProduct]);

    // grouping additional assortment
    const AdditionalGroups: AdditionalProductsGroup[] = useMemo(() => {
        const groups: AdditionalProductsGroup[] = [
            { description: 'Blacha płaska', icon: 'blachy.png', products: [], indexes: [], shortNames: [] },
            { description: 'Gąsiory', icon: 'gasiory.png', products: [], indexes: [], shortNames: [] },
            { description: 'Farby', icon: 'farby.png', products: [], indexes: [], shortNames: [] },
            { description: 'Wkręty', icon: 'wkrety.png', products: [], indexes: [], shortNames: [] },
            { description: 'Uszczelki', icon: 'uszczelki.png', products: [], indexes: [], shortNames: [] },
            { description: 'Uszczelki', icon: 'uszczelki.png', products: [], indexes: [], shortNames: [] },
            { description: 'Pozostałe', icon: 'pozostale.png', products: [], indexes: [], shortNames: [] }
        ];

        _.map(selectedProduct.additionalAssortment, (prd, index) => {
            let grp = 5;
            let short = '';
            switch (prd.tag) {
                case 'IsPF': grp = 0; short = 'z folią'; break;
                case 'IsGM': grp = 1; short = 'mały'; break;
                case 'IsGD': grp = 1; short = 'duży'; break;
                case 'IsGO': grp = 1; short = 'kalenica OMEGA'; break;
                case 'IsF': grp = 2; short = 'farby'; break;
                case 'IsWf': grp = 3; short = 'farmery'; break;
                case 'IsWs5': grp = 3; short = 'samowiertne 5mm'; break;
                case 'IsWs12': grp = 3; short = 'samowiertne 12mm'; break;
                case 'IsUg': grp = 4; short = 'gąsior'; break;
                case 'IsUr': grp = 4; short = 'rynna'; break;
                case 'IsUu': grp = 5; short = 'uniwersalna'; break;
                case 'IsUgr': grp = 5; short = 'komplet'; break;
                case 'IsFo': grp = 6; short = 'membrana 135g'; break;
                case 'IsKW': grp = 6; short = 'kominek wentylacyjny'; break;
                default: break;
            }
            groups[grp].products.push(prd);
            groups[grp].indexes.push(index);
            groups[grp].shortNames.push(short);
        });
        return groups;
    }, [selectedProduct.additionalAssortment]);

    useEffect(() => {
        if (productAddedToCart) {
            _.map(selectedProduct.additionalAssortment, (prd, index) => {
                const prdInCart = _.find(additionalAssortmentInCart, (assInCart) => assInCart.product.symKar === prd.symKar);
                if (prdInCart === undefined && additionalAssortmentQuantity[index] > 0) {
                    const productDetails = _.find(productList, (productFromList) => productFromList.symKar === prd.symKar);
                    if (productDetails) {
                        store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
                            groupPath: _.join(productDetails.path, ' ') as string,
                            product: {
                                product: productDetails as Product,
                                dimension: productDetails.dimensionDefault ?? 1,
                                quantity: additionalAssortmentQuantity[index],
                                additionsSelected: _.map(productDetails.additions, (add) => add.default),
                                mainProductSymkar: selectedProduct.symKar,
                                unit: getDefaultUnitOfProduct(productDetails)
                            },
                            productsType: 'additionalProducts'
                        }));
                    }
                } else if (prdInCart && additionalAssortmentQuantity[index] > 0) {
                    store.dispatch(dataActions[edit ? 'editProductsInLocalEditCart' : 'editProductsInLocalCart']({
                        groupPath: prdInCart.groupPath,
                        index: prdInCart.indexInGroup,
                        product: {
                            ...prdInCart,
                            quantity: additionalAssortmentQuantity[index]
                        },
                        productsType: 'additionalProducts'
                    }));
                } else if (prdInCart && additionalAssortmentQuantity[index] === 0) {
                    store.dispatch(dataActions[edit ? 'deleteFromLocalEditCart' : 'deleteFromLocalCart']({
                        groupPath: prdInCart.groupPath,
                        index: prdInCart.indexInGroup,
                        productsType: 'additionalProducts',
                        symKar: prd.symKar
                    }));
                }
            });
            if (isDesktop) setShowCartMessage();
            showNotification('Zaktualizowano asortyment dodatkowy w koszyku');
            setProductAddedToCart(false);
        }
    }, [productAddedToCart]);

    const addAdditionalToCart = async () => {
        const productsToQuery = _.map(
            _.filter(selectedProduct.additionalAssortment, (addition, index) => additionalAssortmentQuantity[index] > 0 && !productList.some((product) => product.symKar === addition.symKar)),
            (addition) => ({ symKar: addition.symKar, unit: addition.unit })
        );

        if (productsToQuery.length > 0) {
            await getProductWithPrice(productsToQuery, true);
        }
        setProductAddedToCart(true);
    };

    const handleClearAdditionalAssortmentQuantity = () => {
        _.map(additionalAssortmentInCart, (prdInCart) => {
            store.dispatch(dataActions[edit ? 'deleteFromLocalEditCart' : 'deleteFromLocalCart']({
                groupPath: prdInCart.groupPath,
                index: prdInCart.indexInGroup,
                productsType: 'additionalProducts',
                symKar: prdInCart.product.symKar
            }));
        });
        if (isDesktop) setShowCartMessage();
        showNotification('Wyczyszczono asortyment dodatkowy');
    };

    const handleAdditionalAssortmentToggle = () => {
        if (!selectedProduct.additionalAssortment.length) return;
        setAdditionalAssortmentShow(!additionalAssortmentShow);
    };

    const handleScrollToAdditionalAssortment = () => {
        if (additionalAssortmentRef && additionalAssortmentRef.current) additionalAssortmentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <div style={{ border: '2px solid rgba(0,0,0,0.125)', gridColumn: '2 span' }}>
            <div
                className={`AdditionalAssortment-addAdditionalAssortmentHeader ${(selectedProduct.additionalAssortment.length === 0) && 'AdditionalAssortment-addAdditionalAssortmentHeader-locked'} d-flex align-items-center`}
                onClick={handleAdditionalAssortmentToggle}
                ref={additionalAssortmentRef}
            >
                <span>ASORTYMENT DODATKOWY</span>
                {selectedProduct.additionalAssortment.length === 0 && <FaTimes size='30px' style={{ marginRight: '5px' }} />}
                {selectedProduct.additionalAssortment.length > 0 && (additionalAssortmentShow ? <FaAngleUp size='26px' style={{ marginRight: '6px' }} /> : <FaAngleDown size='26px' style={{ marginRight: '6px' }} />)}
            </div>
            <div className='AdditionalAssortment-additionalAssortmentContainer'>
                <Collapse in={additionalAssortmentShow} onEntered={handleScrollToAdditionalAssortment}>
                    <div>
                        <div className='AdditionalAssortment-additionalAssortmentGrid'>
                            {_.map(AdditionalGroups, ({ products, icon, indexes, description, shortNames }, groupId) => products.length > 0 && (
                                <div key={`AdditionalAssortment-${groupId}`} className='AdditionalAssortment-additionalGroupCard'>
                                    <div className='group-header'>
                                        <p>{description}</p>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/${icon}`} alt={icon} />
                                    </div>
                                    {_.map(products, (prd, index) => (
                                        <div key={`AdditionalAssortment-${groupId}-${index}`} className='prd-line'>
                                            <Form.Group controlId='quantity'>
                                                <span>
                                                    {shortNames[index]}
                                                    {' '}
                                                    [
                                                    {prd.unit}
                                                    .]
                                                </span>
                                                <NumberInput
                                                    value={additionalAssortmentQuantity[indexes[index]]}
                                                    setValue={(value: number) => {
                                                        const newQuantity = [...additionalAssortmentQuantity];
                                                        newQuantity[indexes[index]] = !Number.isNaN(value) ? Math.abs(value) : 1;
                                                        setAdditionalAssortmentQuantity(newQuantity);
                                                    }}
                                                    minDesktopResolution={LG}
                                                    disabledEdit={additionalAssortmentQuantity[indexes[index]] === 0}
                                                    min={0}
                                                />
                                            </Form.Group>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className='AdditionalAssortment-buttonsContainer'>
                            {!isAdditionalAssortmentFetching ? (
                                <ButtonComponent
                                    text='AKTUALIZUJ ASORTYMENT DODATKOWY'
                                    onClick={addAdditionalToCart}
                                    onHoverAnimation={3}
                                    width='324px'
                                />
                            ) : (
                                <ButtonComponent
                                    disabled
                                    width='324px'
                                >
                                    <Spinner animation='border' size='sm' style={{ margin: '0 auto', display: 'block' }} />
                                </ButtonComponent>
                            )}

                            <ButtonComponent
                                text='WYCZYŚĆ'
                                variant='danger'
                                onClick={handleClearAdditionalAssortmentQuantity}
                                onHoverAnimation={3}
                                height='42px'
                            />
                            <div className={`AdditionalAssortment-cartMessage ${showCartMessage ? 'show' : ''}`} style={{ height: '42px' }}>
                                Zaktualizowano produkty w koszyku
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>

    );
};

export default AdditionalAssortment;
