import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { Card, Form, Modal, Nav, Spinner } from 'react-bootstrap';
import { FaAngleLeft, FaShoppingCart } from 'react-icons/fa';
import { BsFillGearFill } from 'react-icons/bs';
import _ from 'lodash';
import { arrayPathToString, AssortmentSelectionState, firstNGroups, GROUP_CATEGORY_NAME, GROUP_ORDER, pathToArray } from './AssortmentSelection';
import { Product, Param, ViewId } from 'lib/types';
import './AssortmentSelection.scss';
import { updateUserParams } from 'lib/communication/userInfo';
import { userService } from 'services';
import ButtonComponent from 'components/ButtonComponent';
import { DefaultOrdersViewSettingWrapper } from 'components/SettingsWrappers';

interface AssortmentSelectionHeaderProps {
    currentLevel: number;
    selectedView: ViewId;
    selectedPath: string;
    selectedProduct: Product | null;
    isSpecialGroup: boolean;
    setShowCart: (_: boolean) => void;
}

const AssortmentSelectionHeader = ({
    currentLevel, selectedView, selectedPath, selectedProduct, isSpecialGroup,
    setShowCart
}: AssortmentSelectionHeaderProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });

    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const cartContent = useSelector((state: AppState) => (edit ? state.data.orders.editCart : state.data.orders.cart));
    const groups = useSelector((state: AppState) => state.data.orders.groupsAndProducts.groups);
    const isPlateCreatorOn = useSelector((state: AppState) => state.conf.isPlateCreatorOn);
    const isFetching = useSelector((state: AppState) => state.data.userInfo.props.isFetching);
    const isCartFetching = useSelector((state: AppState) => state.data.orders.props.isCartFetching);
    const showCartMessage = useSelector((state: AppState) => state.data.orders.showCartMessage);

    const history = useHistory<AssortmentSelectionState>();
    const [showSettings, setShowSettings] = useState(false);
    const [settingsMessage, setSettingsMessage] = useState('');

    const [defaultNewOrderViewDesktop, setDefaultNewOrderViewDesktop] = useState<string>();
    const [defaultNewOrderViewMobile, setDefaultNewOrderViewMobile] = useState<string>();

    useEffect(() => {
        const params = userService.getUserParams();
        params?.forEach((param) => {
            if (param.ParamName === 'DefaultNewOrderView') {
                setDefaultNewOrderViewDesktop(param.ParamValue);
            }
            if (param.ParamName === 'DefaultNewOrderViewMobile') {
                setDefaultNewOrderViewMobile(param.ParamValue);
            }
        });
    });

    const settingsForm = useRef<HTMLFormElement>(null);
    const settingsSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const {
            newDefaultAssortmentViewDesktop: { value: newDesktop },
            newDefaultAssortmentViewMobile: { value: newMobile }
        } = (e.currentTarget as any);

        const updateParamViewDesktop: Param = {
            ParamName: 'DefaultNewOrderView',
            ParamValue: newDesktop
        };

        const updateParamViewMobile: Param = {
            ParamName: 'DefaultNewOrderViewMobile',
            ParamValue: newMobile
        };

        const params = await updateUserParams(updateParamViewDesktop, updateParamViewMobile);
        setSettingsMessage(params.ok ? 'Zapisano' : 'Błąd');
    };

    const settingsModal = () => (
        <Modal
            show={showSettings}
            onHide={() => setShowSettings(false)}
            size={!isDesktop ? 'lg' : undefined}
            dialogClassName='NewOrder-dialog dialog-settings'
            backdrop='static'
            centered
        >
            <Modal.Header closeButton={isDesktop}>
                {!isDesktop && <FaAngleLeft size='40px' onClick={() => setShowSettings(false)} />}
                <Modal.Title>
                    Ustawienia
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={settingsSubmit} ref={settingsForm}>
                    <DefaultOrdersViewSettingWrapper
                        isPlateCreatorOn={isPlateCreatorOn}
                        isDesktop={isDesktop}
                        headerText='Domyślny widok (desktopowy)'
                        initialValue={defaultNewOrderViewDesktop ?? ''}
                    />
                    <hr />
                    <DefaultOrdersViewSettingWrapper
                        isPlateCreatorOn={isPlateCreatorOn}
                        isDesktop={isDesktop}
                        headerText='Domyślny widok (mobilny)'
                        initialValue={defaultNewOrderViewMobile ?? ''}
                        isForMobileView
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                    text='Zapisz'
                    onClick={() => settingsForm.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
                    variant='primary'
                    onHoverAnimation={3}
                />
                <div style={{ fontStyle: 'italic', marginRight: 'auto', color: settingsMessage === 'Błąd' ? 'red' : 'green' }}>{isFetching ? <Spinner style={{ margin: '0px' }} animation='border' /> : settingsMessage}</div>
                <ButtonComponent
                    text='Zamknij'
                    onClick={() => setShowSettings(false)}
                    variant='secondary'
                    onHoverAnimation={3}
                />
            </Modal.Footer>
        </Modal>
    );

    return isDesktop ? (
        <Card.Header className='AssortmentSelection-CardHeader'>
            {settingsModal()}
            <div className='AssortmentSelection-CardHeaderTop'>
                {['basic', 'advanced'].includes(selectedView) && !isSpecialGroup && (
                    <div className='AssortmentSelection-systemHeader'>
                        {_.map(_.dropRight(GROUP_ORDER, GROUP_ORDER.length - (selectedProduct ? selectedProduct.path : pathToArray(selectedPath)).length + 1), (level, index) => {
                            const path = selectedProduct ? arrayPathToString(selectedProduct.path) : selectedPath;
                            return (
                                <div key={`span${index}`}>
                                    {level === 1 ? 'Asortyment:' : isSpecialGroup ? ':' : `${GROUP_CATEGORY_NAME[level - 2]}:`}
                                    <b>
                                        {_.find(groups, (g) => firstNGroups(path, index + 2) === g.path)?.description}
                                    </b>
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className='AssortmentSelection-CardHeaderNav'>
                    <Nav
                        variant='tabs'
                        activeKey={selectedView}
                        onSelect={(selectedKey) => history.push({ pathname: `${process.env.PUBLIC_URL}/new-order`, state: { ...history.location.state, selectedView: selectedKey !== null ? selectedKey as ViewId : 'basic' } })}
                    >
                        <Nav.Item className={selectedView === 'basic' ? 'AssortmentSelection-CardHeaderNav-isActive' : ''}>
                            <Nav.Link eventKey='basic'>Wyszukiwanie proste</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={selectedView === 'advanced' ? 'AssortmentSelection-CardHeaderNav-isActive' : ''}>
                            <Nav.Link eventKey='advanced'>Wyszukiwanie zaawansowane</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={selectedView === 'gutters' ? 'AssortmentSelection-CardHeaderNav-isActive' : ''}>
                            <Nav.Link eventKey='gutters'>Orynnowanie</Nav.Link>
                        </Nav.Item>
                        <div className='AssortmentSelection-CardHeaderRight-icons'>
                            <BsFillGearFill
                                size={16}
                                className='settings-icon'
                                onClick={() => setShowSettings(true)}
                            />
                        </div>
                        {
                            isPlateCreatorOn && (
                                <Nav.Item className={selectedView === 'plate' ? 'AssortmentSelection-CardHeaderNav-isActive' : ''}>
                                    <Nav.Link eventKey='plate'>Kreator płyt warstwowych</Nav.Link>
                                </Nav.Item>
                            )
                        }
                    </Nav>
                </div>
            </div>
            <div className='AssortmentSelection-CardHeaderRight'>
                <div className='AssortmentSelection-CardHeaderTop-cartIndicator'>
                    <div className={`AssortmentSelection-cartMessage ${showCartMessage ? 'show' : ''}`}>
                        Zaktualizowano produkty w koszyku
                    </div>
                    <div className='AssortmentSelection-cartLeft' onClick={() => setShowCart(true)}>
                        {isCartFetching ? <Spinner animation='border' className='spinner-border' /> : (
                            <>
                                <div className='AssortmentSelection-itemsCount'>
                                    <span style={{ visibility: 'hidden' }}>{cartContent.itemsCount}</span>
                                    <div className='AssortmentSelection-itemsCount-value'>{cartContent.itemsCount}</div>
                                </div>
                                <div>
                                    <FaShoppingCart className='AssortmentSelection-cartIcon' size={32} />
                                </div>
                            </>
                        )}
                    </div>
                    <div className='AssortmentSelection-border-desktop'>
                        {cartContent.summaryPrice?.toFixed(2)}
                    </div>
                </div>
            </div>
        </Card.Header>
    ) : (
        <>
            {settingsModal()}
            <Card.Header className='AssortmentSelection-CardHeader'>
                <div>
                    <div style={{ minHeight: '65px' }}>
                        {isCartFetching ? <Spinner animation='border' /> : (
                            <>
                                <div style={{ display: 'block' }}>
                                    <FaShoppingCart className='AssortmentSelection-cartIcon' onClick={() => setShowCart(true)} size={60} />
                                </div>
                                <div className='AssortmentSelection-border' onClick={() => setShowCart(true)}>
                                    <b>{cartContent.itemsCount}</b>
                                </div>
                            </>
                        )}
                    </div>
                    <BsFillGearFill
                        size={24}
                        className='settings-icon'
                        onClick={() => setShowSettings(true)}
                    />
                </div>
                <div className='AssortmentSelection-systemHeader-mobile'>
                    {['basic', 'advanced'].includes(selectedView) && currentLevel !== 0 && (
                        <div className='AssortmentSelection-systemHeader'>
                            {_.map(_.dropRight(GROUP_ORDER, GROUP_ORDER.length - currentLevel), (level, index) => (
                                <div key={`span${index}`}>
                                    {level === 1 ? 'Asortyment:' : isSpecialGroup ? ':' : `${GROUP_CATEGORY_NAME[level - 2]}:`}
                                    <b>
                                        {_.find(groups, ({ path }) => firstNGroups(selectedPath, index + 2) === path)?.description}
                                    </b>
                                </div>
                            ))}
                        </div>
                    )}
                    <Nav
                        justify
                        variant='pills'
                        activeKey={selectedView}
                        className='AssortmentSelection-searching'
                        defaultActiveKey={selectedView}
                        onSelect={(selectedKey) => history.push({ pathname: `${process.env.PUBLIC_URL}/new-order`, state: { ...history.location.state, selectedView: selectedKey !== null ? selectedKey as ViewId : 'basic' } })}
                    >
                        <Nav.Item className={selectedView === 'basic' ? 'AssortmentSelection-CardHeaderNav-isActive' : ''}>
                            <Nav.Link className='nav' eventKey='basic' style={{ color: 'black' }}>
                                Wyszukiwanie proste
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={selectedView === 'advanced' ? 'AssortmentSelection-CardHeaderNav-isActive' : ''}>
                            <Nav.Link eventKey='advanced' style={{ color: 'black' }}>Wyszukiwanie zaawansowane</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </Card.Header>
        </>
    );
};

export default AssortmentSelectionHeader;
