import { LG } from 'lib/util';
import _ from 'lodash';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AppState } from 'reducers';

export default function LatestSchemaValidationResults() {
    const latestResults = useSelector((state: AppState) => state.data.schemaValidationResults.latestResults);

    const isDesktop = useMediaQuery({ minWidth: LG });

    return (
        <>
            <div className='TestsResults-container'>
                <div className='TestsResults-body'>
                    <Table bordered striped className={isDesktop ? '' : 'mobile-font-size'}>
                        <thead>
                            <tr>
                                {isDesktop && <th>LP.</th>}
                                <th>Data</th>
                                <th>Nazwa procedury</th>
                                <th>Opis błędu</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(latestResults, (result, index) => (
                                    <tr style={{ borderTop: '2px solid grey' }} key={`${result.procedure_name}/${result.date}`}>
                                        {isDesktop && <td className={'Table-cell'}>{index + 1}</td>}
                                        <td className={'Table-cell'} style={isDesktop ? { overflowWrap: 'break-word' } : { overflowWrap: 'anywhere' }}>
                                            {result.date}
                                        </td>
                                        <td className={'Table-cell'} style={isDesktop ? { overflowWrap: 'break-word' } : { overflowWrap: 'anywhere' }}>
                                            {result.procedure_name}
                                        </td>
                                        <td className={'Table-cell'} style={isDesktop ? { overflowWrap: 'break-word' } : { overflowWrap: 'anywhere' }}>
                                            {result.error}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
}
