import { convertStringArrayToString, LG } from 'lib/util';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AppState } from 'reducers';
import _ from 'lodash';
import PrintStatus from '../PrintStatus';

export default function SchemaValidationResults() {
    const testResults = useSelector((state: AppState) => state.data.schemaValidationResults.results);

    const isDesktop = useMediaQuery({ minWidth: LG });

    return (
        <>
            <div className='TestsResults-container'>
                <div className='TestsResults-body'>
                    <Table bordered striped className={isDesktop ? '' : 'mobile-font-size'}>
                        <thead>
                            <tr>
                                {isDesktop && <th>LP.</th>}
                                <th>Procedura</th>
                                {
                                    (testResults && Object.keys(testResults).length !== 0) &&
                                    _.map(Object.keys(testResults[_.take(Object.keys(testResults), 1)[0]]), (date) => (
                                        <th key={`header-${date}`}>{date}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(Object.entries(testResults), ([procedureName, procedureResults], index) => (
                                    <tr style={{ borderTop: '2px solid grey' }} key={procedureName}>
                                        {isDesktop && <td className={'Table-cell'}>{index + 1}</td>}
                                        <td className={'Table-cell'} style={isDesktop ? { overflowWrap: 'break-word' } : { overflowWrap: 'anywhere' }}>
                                            {procedureName}
                                        </td>
                                        {
                                            _.map(procedureResults, (result, nestedIndex) => (
                                                <td key={100 * index + nestedIndex}><PrintStatus isError={result.data.length > 0} errorType={result.data.length > 0 ? convertStringArrayToString(result.data) : undefined} />  {result.data.length > 0 && `(${result.numberOfErrors})`}</td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
}
