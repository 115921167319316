import { TownOptionType } from 'lib/types';
import React, { ReactNode } from 'react';
import { MenuListComponentProps } from 'react-select';
import { FixedSizeList as List } from 'react-window';

const CustomMenuList = (props: MenuListComponentProps<TownOptionType>) => {
    const ITEM_HEIGHT = 35;
    const MAX_VISIBLE_ITEMS = 8;

    const { children, maxHeight, selectProps, options, innerRef } = props;
    const inputValue = selectProps.inputValue as string;
    const noOptionsMessage = selectProps.noOptionsMessage as (args: { inputValue: string }) => string;
    const initialOffset = 0;

    if (options.length === 0) {
        return (
            <div ref={innerRef} style={{ maxHeight, padding: '10px', textAlign: 'center', color: 'grey' }}>
                {noOptionsMessage({ inputValue })}
            </div>
        );
    }

    return (
        <List
            height={Math.min(options.length, MAX_VISIBLE_ITEMS) * ITEM_HEIGHT}
            width={'100%'}
            itemCount={(children as ReactNode[]).length}
            itemSize={ITEM_HEIGHT}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }) => (<div style={style}>{(children as ReactNode[])[index]}</div>)}
        </List>
    );
};

export default CustomMenuList;
