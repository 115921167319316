import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
import store from 'store';
import { AppState, dataActions } from 'reducers';
import { Product, Unit } from 'lib/types';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import './ProductSelection.scss';
import { useSelector } from 'react-redux';
import { getDefaultUnitOfProduct, mapLocalCartToCartContents } from 'lib/utilityMetods';
import NumberInput from 'components/NumberInput';
import ButtonComponent from 'components/ButtonComponent';
import useCallbackState from 'lib/hooks/useCallbackState';
import { useNotification } from 'components/NotificationComponent/NotificationComponent';
import { addProductToCart } from 'lib/gtm-utils';
import useCartMessage from 'lib/hooks/useCartMessage';

interface ProductSelectionProps {
    selectedProduct: Product;
    inputRef?: React.RefObject<HTMLInputElement>;
}

const ProductSelection = ({ selectedProduct, inputRef }: ProductSelectionProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const { setShowCartMessage } = useCartMessage();

    const edit = useSelector((state: AppState) => state.data.orders.edit);
    const localEditCart = useSelector((state: AppState) => state.data.orders.localEditCart);
    const showNotification = useNotification();

    const productDefaultDimension = Number(selectedProduct.dimensionDefault ?? 1);
    const [productDimension, setProductDimension] = useState(productDefaultDimension);
    const [selectedAdditions, setSelectedAdditions] = useState(Array<boolean>());
    const [productQuantity, setProductQuantity] = useCallbackState<number>(1);

    const [correctAdditions, setCorrectAdditions] = useState(true);
    const [correctQuantity, setCorrectQuantity] = useState(true);
    const [resetNumberInputAdditions, setResetNumberInputAdditions] = useState(false);
    const [resetNumberInputQuantity, setResetNumberInputQuantity] = useState(false);

    const [selectedUnit, setSelectedUnit] = useState<Unit>({ unit: 'szt', isDefault: false, converter: 1, description: 'sztuki' });

    const makeGroupName = () => _.join(selectedProduct?.path, ' ');

    const totalQuantity = +(productQuantity * (selectedProduct.isDimension ? productDimension : 1)).toFixed(2);
    const multiplyByTotalQuantity = (value: number): number => +(totalQuantity * value).toFixed(2);
    const productPriceData = selectedProduct ? (() => {
        const { discount, price, vatRate } = selectedProduct.priceInfo;
        const additionsPrice = _.reduce(
            selectedAdditions,
            (sum, isSelected, index) => (
                isSelected ? sum + selectedProduct.additions[index].price : sum
            ),
            0
        );
        const priceAfterDiscount = (price + additionsPrice) * (1 - discount / 100);
        const vat = vatRate / 100;
        const gross = priceAfterDiscount * (1 + vat);

        return {
            price: multiplyByTotalQuantity(price),
            discount,
            priceAfterDiscount: multiplyByTotalQuantity(priceAfterDiscount),
            vat: multiplyByTotalQuantity(vat * priceAfterDiscount),
            gross: multiplyByTotalQuantity(gross)
        };
    })() : null;

    const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const unit = selectedProduct.priceInfo.units[parseInt(e.currentTarget.value)];

        setSelectedUnit(unit);
    };

    const addProductSubmit = () => {
        setShowCartMessage();
        if (productQuantity > 0 && (productDimension ?? 1) > 0 && selectedUnit) {
            const product = {
                product: selectedProduct as Product,
                dimension: productDimension,
                quantity: productQuantity as number,
                additionsSelected: selectedAdditions,
                mainProductSymkar: null,
                unit: selectedUnit
            };
            store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
                groupPath: makeGroupName() as string,
                product,
                productsType: 'mainProducts'
            }));
            addProductToCart(product);
            setProductQuantity(1);
            setProductDimension(productDefaultDimension);
            setResetNumberInputAdditions(true);
            setResetNumberInputQuantity(true);
        }
    };

    useEffect(() => {
        setProductDimension(productDefaultDimension);
        setProductQuantity(1, () => inputRef?.current?.select());
        setSelectedAdditions(_.map(selectedProduct?.additions, (addition) => addition.default ?? false));
        setSelectedUnit(getDefaultUnitOfProduct(selectedProduct));
    }, [selectedProduct, productDefaultDimension]);

    useEffect(() => {
        store.dispatch(dataActions.setEditCart(mapLocalCartToCartContents(localEditCart)));
    }, [localEditCart]);

    if (!productPriceData) return null;

    const PriceDisplay = () => (
        <>
            <Row>
                <Col>
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                        <span>JM: </span>
                        {selectedProduct.priceInfo.units.length > 1 ?
                            <Form.Control
                                as='select'
                                onChange={handleUnitChange}
                                style={{ padding: '3px', height: 'auto', maxWidth: 'fit-content' }}
                                defaultValue={selectedProduct.priceInfo.units.findIndex((u) => selectedUnit.unit === u.unit)}
                            >
                                {selectedProduct.priceInfo.units.map((unit, index) => (
                                    <option key={`${selectedProduct.symKar}-${unit.unit}`} value={index}>
                                        {unit.description}
                                    </option>
                                ))}
                            </Form.Control> :
                            getDefaultUnitOfProduct(selectedProduct).unit}
                    </div>
                </Col>
                <Col>
                    <span>Ilość w JM: </span>
                    <b>{totalQuantity}</b>
                </Col>
                <Col>
                    <span>
                        Ilość w m
                        <sup>2</sup>
                        :
                        {' '}
                    </span>
                    <b>{(totalQuantity * selectedProduct.converterM2).toFixed(4)}</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <span>Netto: </span>
                    <b>{(productPriceData.priceAfterDiscount * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
                </Col>
                <Col>
                    <span>VAT: </span>
                    <b>{(productPriceData.vat * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
                </Col>
                <Col>
                    <span>Brutto: </span>
                    <b>{(productPriceData.gross * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
                </Col>
            </Row>
        </>
    );

    return isDesktop ? (
        <div className='AssortmentSelection-addProduct-container'>
            <div className='ProductSelection-addProduct ProductSelection-addProduct-desktop'>
                <div className='ProductSelection-addProduct-desktop-header'>
                    <span className='ProductSelection-addProduct-desktop-title'>Dodaj produkt</span>
                    <hr />
                </div>
                <div className='NewOrder-typeElem' onKeyPress={({ key }) => key === 'Enter' && addProductSubmit()}>
                    <Form.Group controlId='quantity' style={{ width: '35%', margin: '0px' }}>
                        <span>Ilość sztuk: </span>
                        <NumberInput
                            value={productQuantity}
                            setValue={(value) => setProductQuantity(value)}
                            minDesktopResolution={LG}
                            disabledEdit={productQuantity === 1}
                            inputRef={inputRef}
                            correct={correctQuantity}
                            setCorrect={setCorrectQuantity}
                            defaultValue={1}
                            reset={resetNumberInputAdditions}
                            setReset={setResetNumberInputAdditions}
                        />
                    </Form.Group>
                    <Form.Group controlId='dimension' style={{ width: '35%', margin: '0px' }}>
                        <span>Wymiar: </span>
                        <NumberInput
                            value={productDimension}
                            setValue={(value) => setProductDimension(value)}
                            step={0.1}
                            min={selectedProduct.dimensionMin ?? 0.01}
                            max={selectedProduct.dimensionMax ?? 10 ** 5}
                            fractDigits={3}
                            disabled={!selectedProduct.dimensionCanEdit}
                            minDesktopResolution={LG}
                            disabledEdit={productDimension === 0.01}
                            correct={correctAdditions}
                            setCorrect={setCorrectAdditions}
                            defaultValue={productDefaultDimension}
                            reset={resetNumberInputAdditions}
                            setReset={setResetNumberInputAdditions}
                            showMinMaxOnInvalidRange
                        />
                    </Form.Group>
                </div>
                <hr />
                <>
                    {selectedProduct.additions.map((addition, index) => (
                        <>
                            <div
                                key={`additionCheck_${addition.name}`}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px 12px',
                                    width: '100%'
                                }}
                            >   <span style={{ flex: 1 }}></span>
                                <span style={{ fontSize: 16, fontWeight: 500, textAlign: 'center', flex: 5, paddingRight: '100px' }}>
                                    {addition.name}
                                </span>
                                <Form.Group controlId={addition.name} style={{ marginBottom: 0, display: 'flex', alignItems: 'center', flex: 6, justifyContent: 'center', paddingRight: '30px' }}>
                                    <Form.Check
                                        name={addition.name}
                                        type='checkbox'
                                        defaultChecked={addition.default}
                                        disabled={addition.default}
                                        onChange={() => {
                                            const newArr = [...selectedAdditions];
                                            newArr[index] = !selectedAdditions[index];
                                            setSelectedAdditions(newArr);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <hr />
                        </>
                    ))}
                </>
                <PriceDisplay />
                <hr />
                <div className='ProductSelection-addToCart-mobile'>
                    <ButtonComponent
                        text='DODAJ DO KOSZYKA'
                        onClick={addProductSubmit}
                        marginBottom='5px'
                        marginTop='5px'
                        disabled={!(correctQuantity && correctAdditions)}
                        onHoverAnimation={3}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div>
            <div className='ProductSelection-addProduct'>
                <div className='ProductSelection-addProductHeader'>
                    <b>{selectedProduct.description}</b>
                </div>
                <div className='NewOrder-typeElem'>
                    <Form.Group controlId='quantity' style={{ margin: '0 3%' }}>
                        <span>Ilość sztuk: </span>
                        <NumberInput
                            value={productQuantity}
                            setValue={(value) => setProductQuantity(value)}
                            minDesktopResolution={LG}
                            disabledEdit={productQuantity === 1}
                            correct={correctQuantity}
                            setCorrect={setCorrectQuantity}
                            defaultValue={1}
                            reset={resetNumberInputQuantity}
                            setReset={setResetNumberInputQuantity}
                        />
                    </Form.Group>
                    <Form.Group controlId='dimension' style={{ margin: '0 3%', marginBottom: '5px' }}>
                        <span>Wymiar: </span>
                        <NumberInput
                            value={productDimension}
                            setValue={(value) => setProductDimension(value)}
                            step={0.1}
                            min={selectedProduct.dimensionMin ?? 0.01}
                            max={selectedProduct.dimensionMax ?? 10 ** 5}
                            fractDigits={3}
                            disabled={!selectedProduct.dimensionCanEdit}
                            minDesktopResolution={LG}
                            disabledEdit={productDimension === 0.01}
                            correct={correctAdditions}
                            setCorrect={setCorrectAdditions}
                            defaultValue={productDefaultDimension}
                            reset={resetNumberInputAdditions}
                            setReset={setResetNumberInputAdditions}
                        />
                    </Form.Group>
                </div>
                <hr />
                <>
                    {selectedProduct.additions.map((addition, index) => (
                        <>
                            <div
                                key={`additionCheck_${addition.name}`}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px 12px',
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    textAlign: 'center'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        textAlign: 'center',
                                        flex: '1',
                                        paddingRight: '10px'
                                    }}
                                >
                                    {addition.name}
                                </span>
                                <Form.Group
                                    controlId={addition.name}
                                    style={{
                                        marginBottom: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: '1',
                                        justifyContent: 'center',
                                        paddingRight: 0
                                    }}
                                >
                                    <Form.Check
                                        name={addition.name}
                                        type='checkbox'
                                        defaultChecked={addition.default}
                                        disabled={addition.default}
                                        onChange={() => {
                                            const newArr = [...selectedAdditions];
                                            newArr[index] = !selectedAdditions[index];
                                            setSelectedAdditions(newArr);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <hr />
                        </>
                    ))}
                </>
                <PriceDisplay />
            </div>
            <div className='ProductSelection-addToCart-mobile'>
                <ButtonComponent
                    text='DODAJ DO KOSZYKA'
                    marginBottom='0px'
                    onClick={() => {
                        if (productQuantity > 0 && (productDimension ?? 1) > 0 && selectedUnit) {
                            const product = {
                                product: selectedProduct as Product,
                                dimension: productDimension,
                                quantity: productQuantity as number,
                                additionsSelected: selectedAdditions,
                                mainProductSymkar: null,
                                unit: selectedUnit
                            };
                            store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
                                groupPath: makeGroupName() as string,
                                product,
                                productsType: 'mainProducts'
                            }));
                            addProductToCart(product);
                        }
                        showNotification('Dodano produkt do koszyka');
                    }}
                    disabled={!(correctQuantity && correctAdditions)}
                    onHoverAnimation={3}
                />
            </div>
        </div>
    );
};

export default ProductSelection;
