import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Spinner as Spin } from 'react-bootstrap';
import './PromotionProducts.scss';
import { AppState } from 'reducers';
import { useSelector } from 'react-redux';
import { DeliveryOption, PromotionalProduct, UserDeliveryAddress } from 'lib/types';
import {
    getPromotionalProducts,
    getPromotionalPoints,
    addPromotionalOrder
} from 'lib/communication/promotionalProgram';
import PromotionProductsList from './PromotionProductsList';
import PromotionProductsSummary from './PromotionProductsSummary';
import ButtonComponent from 'components/ButtonComponent';
import _ from 'lodash';

const PromotionProducts = () => {
    const products = useSelector((state: AppState) => state.data.promotionalProgram.products);
    const points = useSelector((state: AppState) => state.data.promotionalProgram.points);
    const pointsSpent = useSelector((state: AppState) => state.data.promotionalProgram.pointsSpent);
    const delivery = useSelector((state: AppState) => state.data.promotionalProgram.delivery);
    const promotion = useSelector((state: AppState) => state.conf.promotion);
    const isFetchingPoints = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetchingPoints);
    const isFetchingProducts = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetchingProducts);
    const [deliveryOption, setDeliveryOption] = useState(null as DeliveryOption | null);
    const [showSummary, setShowSummary] = useState(false);
    const [pointsFetchError, setPointsFetchError] = useState(false);
    const [productsFetchError, setProductsFetchError] = useState(false);
    const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningShown, setWarningShown] = useState(false);

    const userDeliveryAddresses = useSelector((state: AppState) => state.data.userInfo.userDeliveryAddresses);
    const [deliveryAddress, setDeliveryAddress] = useState<UserDeliveryAddress | undefined>();
    const [addresses, setAddresses] = useState<UserDeliveryAddress[]>([]);

    useEffect(() => {
        if (promotion !== '') {
            getPromotionalPoints(promotion)
                .then((result) => setPointsFetchError(!result));

            if (products.length === 0 && !isFetchingProducts) {
                getPromotionalProducts(promotion)
                    .then((result) => setProductsFetchError(!result));
            }
        }
    }, [promotion, products]);

    useEffect(() => {
        const address = userDeliveryAddresses ?? [];
        setAddresses(address);
    }, [userDeliveryAddresses]);

    const [selectedCount, setSelectedCount] = useState<Map<PromotionalProduct, number[]>>(new Map());

    useEffect(() => {
        const map = new Map<PromotionalProduct, number[]>();
        products.forEach((product) => {
            map.set(product, product.options?.map((option) => 0) ?? [0]);
        });
        setSelectedCount(map);
    }, [products]);

    const availablePoints = points;
    const spentPoints = pointsSpent;
    let grandTotalPoints = Array.from(selectedCount.entries())
        .reduce((previous, entity) => previous + entity[1].reduce((prev, curr) => prev + curr) * entity[0].points, 0);
    if (deliveryOption === 'inputAddress' && deliveryAddress !== undefined && !(deliveryAddress?.readOnly && !deliveryAddress?.special)) grandTotalPoints += delivery.points;
    const remainingPoints = availablePoints - grandTotalPoints;

    useEffect(() => {
        if (remainingPoints < 0) {
            setShowWarning(true);
        }
    }, [remainingPoints]);

    useEffect(() => {
        if (remainingPoints >= 0) {
            setWarningShown(false);
        }
    }, [remainingPoints]);

    useEffect(() => {
        if (remainingPoints >= 0) {
            setShowWarning(false);
        }
    }, [remainingPoints]);

    const submitNewOrder = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let address: UserDeliveryAddress | null;
        switch (deliveryOption) {
            case 'inputAddress':
                address = deliveryAddress || _.find(userDeliveryAddresses, { readOnly: true, special: false }) as UserDeliveryAddress;
                break;
            case 'distributorAddress':
                address = _.find(userDeliveryAddresses, { readOnly: true, special: false }) as UserDeliveryAddress;
                break;
            default:
                throw new Error('Unknown delivery method');
        }
        const productsInOrder = Array.from(selectedCount.entries())
            .flatMap(([product, counts]) => Array.from(counts.entries())
                .filter(([option, value]) => value !== 0)
                .map(([index, value]) => ({ quantity: value, symKar: product.symKar, option: product.options[index] })));
        if (deliveryOption === 'inputAddress' && deliveryAddress !== undefined && !(deliveryAddress?.readOnly && !deliveryAddress?.special)) {
            productsInOrder.push({ quantity: 1, symKar: delivery.symKar, option: '' });
        }

        const sendOrder = async () => {
            const orderAdded = await addPromotionalOrder(
                productsInOrder,
                address as UserDeliveryAddress
            );

            if (orderAdded) {
                getPromotionalPoints(promotion)
                    .then((result) => setPointsFetchError(!result));
            }

            const map = new Map<PromotionalProduct, number[]>();
            products.forEach((product) => {
                map.set(product, product.options?.map((option) => 0) ?? [0]);
            });

            setSelectedCount(map);
            setShowSummary(false);
            setDeliveryOption(null);
            setShowOrderConfirmation(orderAdded);
        };
        sendOrder();
    };

    return (
        <>
            <Table size='sm' borderless className='tr1 tr2 tr3'>
                <thead>
                    <tr>
                        <th>Posiadane punkty</th>
                        <th>Wydane punkty</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (isFetchingPoints || pointsFetchError) ? (
                            <tr>
                                <td>
                                    <Spin animation='border' id='spin' className='PromotionProducts-points-spiner' size='sm' />
                                </td>
                                <td>
                                    <Spin animation='border' id='spin' className='PromotionProducts-points-spiner' size='sm' />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>{availablePoints.toLocaleString('fr-FR')}</td>
                                <td>{spentPoints.toLocaleString('fr-FR')}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            {
                showSummary ? (
                    <PromotionProductsSummary
                        grandTotalPoints={grandTotalPoints}
                        remainingPoints={remainingPoints}
                        selectedCount={selectedCount}
                        deliveryOption={deliveryOption}
                        setDeliveryOption={setDeliveryOption}
                        setShowSummary={setShowSummary}
                        submitNewOrder={submitNewOrder}
                        addresses={addresses}
                        deliveryAddress={deliveryAddress}
                        setDeliveryAddress={setDeliveryAddress}
                    />
                ) : (
                    <PromotionProductsList
                        error={productsFetchError}
                        selectedCount={selectedCount}
                        remainingPoints={remainingPoints}
                        setSelectedCount={setSelectedCount}
                        setShowSummary={setShowSummary}
                    />
                )
            }
            <Modal show={showOrderConfirmation} onHide={() => setShowOrderConfirmation(false)}>
                <Modal.Body>Zamówienie zostało poprawnie złożone</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowOrderConfirmation(false)}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={!!(showWarning && !warningShown)} onHide={() => setWarningShown(true)}>
                <Modal.Header className='PromotionProducts-modal-header' />
                <Modal.Body>Niewystarczająca ilość punktów</Modal.Body>
                <Modal.Footer className='PromotionProducts-modal-footer'>
                    <ButtonComponent onHoverAnimation={3} text='Zamknij' onClick={() => setWarningShown(true)} />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PromotionProducts;
