import { useDispatch } from 'react-redux';
import { dataActions } from 'reducers/data';

const useCartMessage = () => {
    const dispatch = useDispatch();

    const setShowCartMessage = () => {
        dispatch(dataActions.setShowCartMessage(true));

        const timeout = setTimeout(() => {
            dispatch(dataActions.setShowCartMessage(false));
        }, 4500);

        return () => clearTimeout(timeout);
    };

    return { setShowCartMessage };
};

export default useCartMessage;
